import {
  ALL_RECEPTIONS_REQUEST_SUCCESS,
  ADD_RECEPTION_SUCCESS,
  UPDATE_RECEPTION_SUCCESS,
  DELETE_RECEPTION_SUCCESS,
} from "../constants/reception";

const initialState = {
  receptions: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_RECEPTIONS_REQUEST_SUCCESS:
      return {
        ...state,
        receptions: action.payload,
      };
    case ADD_RECEPTION_SUCCESS:
      return {
        ...state,
        receptions: [...state.receptions, action.payload.reception],
      };
    case UPDATE_RECEPTION_SUCCESS:
      return {
        ...state,
        receptions: state.receptions.map((item) => {
          return action.payload.reception.id === item.id
            ? { ...action.payload.reception }
            : { ...item };
        }),
      };
    case DELETE_RECEPTION_SUCCESS:
      return {
        ...state,
        receptions: state.receptions.filter((item) => action.payload.id !== item.id),
      };
    default:
      return state;
  }
};
export default reducer;
