import React, { useEffect } from "react";
import { Role } from "../services/role";

import { useDispatch, useSelector } from "react-redux";

import { addCompanyUser, deleteUser, updateUser } from "../redux/actions/user.admin";
import { ALL_USERS_REQUEST } from "../redux/constants/user";
import { companyUserFormOptions, companyUserFormOptionsOwner } from "../services/formOptions";

import PageTitle from "../components/PageTitle";
import SubmitModal from "../containers/SubmitModal";
import Loader from "../components/Loader";
import EnlargedImageDialog from "../components/EnlargedImageDialog";
import ClickToEnlarge from "../components/ClickToEnlarge";

import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";

import MUIDataTable from "mui-datatables";
import TableTheme from "../utils/TableTheme";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";

const initialDialogOptions = {
  title: "",
  descriptionText: "",
  formOptions: {
    initialValues: {},
    fields: [],
    validationSchema: null,
  },
  open: false,
  submitHandler: () => {},
  handleClose: () => {},
};

const ManageCompanyUsers = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const requesting = useSelector((state) => state.request.requesting);
  const data = useSelector((state) => state.admin.users);
  const isOwner = user.role === "CompanyOwner" ? true : false;
  const formOptions = isOwner ? companyUserFormOptionsOwner : companyUserFormOptions;
  const [currentDialogOptions, setCurrentDialogOptions] = React.useState(initialDialogOptions);

  const handleOpenDialog = ({ title, descriptionText, formOptions, submitHandler }) => {
    setCurrentDialogOptions({
      title: title,
      descriptionText: descriptionText,
      formOptions: formOptions,
      open: true,
      submitHandler: submitHandler,
      handleClose: handleCloseDialog,
    });
  };

  const handleCloseDialog = () => {
    setCurrentDialogOptions(initialDialogOptions);
  };

  const [enlargedImageDialogOpen, setEnlargedImageDialogOpen] = React.useState(false);
  const [currentEnlargedImage, setCurrentEnlargedImage] = React.useState("");

  const handleClickEnlargeImage = (imageLink) => {
    setEnlargedImageDialogOpen(true);
    setCurrentEnlargedImage(imageLink);
  };
  const handleCloseEnlargedImage = () => {
    setEnlargedImageDialogOpen(false);
    setCurrentEnlargedImage("");
  };

  useEffect(() => {
    dispatch({ type: ALL_USERS_REQUEST });
  }, [dispatch]);

  const addUserSubmitHandler = async (data) => {
    dispatch(addCompanyUser(data));
  };
  const updateUserSubmitHandler = async (id, data) => {
    dispatch(updateUser(id, data));
  };

  const deleteUserHandler = async (id) => {
    dispatch(deleteUser(id));
  };

  const options = {
    filterType: "checkbox",
    print: false,
    selectableRows: "none",
    sortOrder: {
      name: "name",
      direction: "asc",
    },
  };
  const columns = [
    {
      name: "id",
      options: {
        filter: false,
        sort: false,
        display: "excluded",
        download: false,
      },
    },
    {
      name: "image",
      label: "Image",
      options: {
        sort: false,
        filter: false,
        download: false,
        customHeadRender: () => (
          <th key={0} style={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }} />
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <ClickToEnlarge imageLink={value} handleClickEnlargeImage={handleClickEnlargeImage} />
            </>
          );
        },
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
      },
    },
    {
      name: "firstName",
      label: "First Name",
      options: {
        filter: false,
      },
    },
    {
      name: "lastName",
      label: "Last Name",
      options: {
        filter: false,
      },
    },
    {
      name: "office",
      label: "Office Location",
      options: {
        filter: false,
        download: false,
      },
    },
    {
      name: "mobile",
      label: "Mobile",
      options: {
        filter: false,
      },
    },
    {
      name: "role",
      label: "Role",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Chip size="small" label={Role[value]} />
            </>
          );
        },
        customFilterListOptions: { render: (value) => Role[value] },
        filterOptions: {
          renderValue: (value) => Role[value],
        },
      },
    },
    {
      name: "id",
      label: "Edit",
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              variant="outlined"
              color="primary"
              disabled={
                (!isOwner && tableMeta.rowData[7] === "CompanyOwner") ||
                user.id === tableMeta.rowData[0]
              }
              onClick={() => {
                handleOpenDialog({
                  title: "Edit User",
                  descriptionText: "",
                  formOptions: {
                    ...formOptions,
                    initialValues: {
                      firstName: tableMeta.rowData[3],
                      lastName: tableMeta.rowData[4],
                      email: tableMeta.rowData[2],
                      office: tableMeta.rowData[5],
                      mobile: tableMeta.rowData[6],
                      role: tableMeta.rowData[7],
                    },
                  },
                  submitHandler: (data) => {
                    updateUserSubmitHandler(value, data);
                  },
                });
              }}
            >
              Edit
            </Button>
          );
        },
      },
    },
    {
      name: "id",
      label: "Delete",
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              variant="outlined"
              color="secondary"
              disabled={
                (!isOwner && tableMeta.rowData[7] === "CompanyOwner") ||
                user.id === tableMeta.rowData[0]
              }
              onClick={(e) => {
                e.preventDefault();
                const confirmAction = window.confirm(
                  `Are you sure you want to delete user ${tableMeta.rowData[3]} ${tableMeta.rowData[4]} ? This action can not be reversed.`
                );
                if (confirmAction) deleteUserHandler(value);
              }}
            >
              Delete
            </Button>
          );
        },
      },
    },
  ];

  if (requesting && !currentDialogOptions.open) {
    return <Loader />;
  }
  return (
    <div>
      <PageTitle
        title={"Manage Users"}
        button={
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleOpenDialog({
                title: "Add new user",
                descriptionText: "",
                formOptions: formOptions,
                submitHandler: addUserSubmitHandler,
              });
            }}
          >
            Create user
          </Button>
        }
      />
      <SubmitModal {...currentDialogOptions} />
      <EnlargedImageDialog
        open={enlargedImageDialogOpen}
        handleClose={handleCloseEnlargedImage}
        imageLink={currentEnlargedImage}
      />
      <MuiThemeProvider theme={TableTheme}>
        <MUIDataTable data={data} columns={columns} options={options} />
      </MuiThemeProvider>
    </div>
  );
};

export default ManageCompanyUsers;
