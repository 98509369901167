export const USER_SET = "USER_SET";
export const USER_UNSET = "USER_UNSET";

export const USER_REQUEST = "USER_REQUEST";
export const USER_REQUEST_SUCCESS = "USER_REQUEST_SUCCESS";
export const USER_REQUEST_FAILURE = "USER_REQUEST_FAILURE";

export const USER_UPDATE_PROFILE = "USER_UPDATE_PROFILE";

export const RESET_PIN = "RESET_PIN";
export const RESET_PIN_SUCCESS = "RESET_PIN_SUCCESS";

export const ALL_USERS_REQUEST = "ALL_USERS_REQUEST";
export const ALL_USERS_REQUEST_FAILURE = "ALL_USERS_REQUEST_FAILURE";
export const ALL_USERS_REQUEST_SUCCESS = "ALL_USERS_REQUEST_SUCCESS";

export const ADD_USER = "ADD_USER";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";

export const MULTIPLE_USERS_DELETE = "MULTIPLE_USERS_DELETE";
export const MULTIPLE_USERS_DELETE_SUCCESS = "MULTIPLE_USERS_DELETE_SUCCESS";

export const ADD_COMPANY_USER = "ADD_COMPANY_USER";
export const ADD_COMPANY_USER_SUCCESS = "ADD_COMPANY_USER_SUCCESS";

export const ADD_CANTEEN_MANAGER = "ADD_CANTEEN_MANAGER";
export const ADD_CANTEEN_MANAGER_SUCCESS = "ADD_CANTEEN_MANAGER_SUCCESS";
