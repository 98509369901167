import axios from "axios";

import config from "./baseRequestConfig";

const getAllReceptionsUrl = "/api/reception/";
const addReceptionUrl = "/api/reception/";
const updateReceptionUrl = "/api/reception/";
const deleteReceptionUrl = "/api/reception/";

export const getAllReceptions = () => {
  const token = config();
  const cfg = {
    headers: { ...axios.defaults.headers, Authorization: token },
  };
  return axios
    .get(getAllReceptionsUrl, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

export const addReception = (reception) => {
  const token = config();
  const cfg = {
    headers: { ...axios.defaults.headers, Authorization: token },
  };
  return axios
    .post(addReceptionUrl, reception, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

export const updateReception = (id, reception) => {
  const token = config();
  const cfg = {
    headers: { ...axios.defaults.headers, Authorization: token },
  };
  return axios
    .put(updateReceptionUrl + id, reception, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

export const deleteReception = (id) => {
  const token = config();
  const cfg = {
    headers: {
      ...axios.defaults.headers,
      Authorization: token,
    },
  };
  return axios
    .delete(deleteReceptionUrl + id, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};
