import React from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { debounce } from "lodash";

import { Formik, Field } from "formik";
import { TextField } from "formik-material-ui";
import { KeyboardDatePicker, KeyboardTimePicker } from "formik-material-ui-pickers";
import ScheduleIcon from "@material-ui/icons/Schedule";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Tooltip from "@material-ui/core/Tooltip";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormHelperText from "@material-ui/core/FormHelperText";

import { withStyles } from "@material-ui/styles";

import { bookingValidationSchema } from "../services/formOptions";
import { requestAvailableRoomsForTimeAndLocation } from "../redux/actions/calendar";
import { mergeDateAndTime } from "../utils/timeUtils";
import { FormLabel } from "@material-ui/core";

const StyledButton = withStyles({
  root: {
    "&.Mui-disabled": {
      pointerEvents: "auto",
    },
  },
})(Button);

const RoomButton = ({ tooltipText, disabled, onClick, ...other }) => {
  const adjustedButtonProps = {
    disabled: disabled,
    component: disabled ? "div" : undefined,
    onClick: disabled ? undefined : onClick,
  };
  return (
    <Tooltip title={tooltipText}>
      <StyledButton {...other} {...adjustedButtonProps} />
    </Tooltip>
  );
};

const BookingFormModal = ({ title, open, handleClose, initialValues, handleSubmit }) => {
  const dispatch = useDispatch();
  const currentLocation = useSelector((state) => state.calendar.currentLocation);
  const availableRooms = useSelector((state) => state.calendar.availableRooms);

  const handleRequestAvailableRooms = debounce((bookingInfo) => {
    if (
      moment(bookingInfo.bookingDate).isValid() &&
      moment(bookingInfo.bookingStartTime).isValid() &&
      moment(bookingInfo.bookingEndTime).isValid() &&
      !(moment(bookingInfo.bookingStartTime).minutes() % 15) &&
      !(moment(bookingInfo.bookingEndTime).minutes() % 15)
    ) {
      const sendValues = {
        id: bookingInfo.id,
        location: currentLocation.id,
        bookingStart: mergeDateAndTime(
          bookingInfo.bookingDate,
          bookingInfo.bookingStartTime,
          moment
        ),
        bookingEnd: mergeDateAndTime(bookingInfo.bookingDate, bookingInfo.bookingEndTime, moment),
      };
      dispatch(requestAvailableRoomsForTimeAndLocation(sendValues));
    }
  }, 500);
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="rules-dialog-title">
      <DialogTitle id="alert-dialog-title" disableTypography>
        <Typography variant="h3">{title}</Typography>
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validateOnChange={true}
        validateOnMount={true}
        validationSchema={bookingValidationSchema}
        onSubmit={(values) => {
          const sendValues = {
            id: values.id,
            name: values.name,
            bookingStart: mergeDateAndTime(values.bookingDate, values.bookingStartTime, moment),
            bookingEnd: mergeDateAndTime(values.bookingDate, values.bookingEndTime, moment),
            room: values.room,
          };
          handleSubmit(sendValues);
        }}
        validate={(values) => {
          const errors = {};
          let bookingDate = moment(values.bookingDate);
          let bookingStartTime = moment(values.bookingStartTime);
          let bookingEndTime = moment(values.bookingEndTime);

          if (bookingDate < moment().hours(0).minutes(0).seconds(0).milliseconds(0)) {
            errors.bookingDate = "You can not create past bookings";
          }
          if (
            moment(bookingDate)
              .hours(bookingStartTime.hours())
              .minutes(bookingStartTime.minutes()) < moment().seconds(0)
          ) {
            errors.bookingStartTime = "You can not create past bookings";
          }
          if (
            moment(bookingDate).hours(bookingEndTime.hours()).minutes(bookingEndTime.minutes()) <
            moment(bookingDate).hours(bookingStartTime.hours()).minutes(bookingStartTime.minutes())
          ) {
            errors.bookingEndTime = "End time can not be before start time";
          }
          if (
            bookingStartTime.hours() === bookingEndTime.hours() &&
            bookingStartTime.minutes() === bookingEndTime.minutes()
          ) {
            errors.bookingEndTime = "End time should be different than start time";
          }

          if (bookingStartTime.minutes() % 15) {
            errors.bookingStartTime = "Please choose 00, 15, 30 or 45 minute slots";
          }
          if (bookingEndTime.minutes() % 15) {
            errors.bookingEndTime = "Please choose 00, 15, 30 or 45 minute slots";
          }

          if (availableRooms.length) {
            if (
              !values.room ||
              !availableRooms.filter((availableRoom) => availableRoom.room.id === values.room)[0]
                .isAvailable
            ) {
              errors.room = "Please select an available room";
              values.room = null;
            }
          }

          return errors;
        }}
      >
        {({
          submitForm,
          values,
          setFieldValue,
          setFieldError,
          errors,
          setFieldTouched,
          touched,
        }) => {
          return (
            <>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <Field
                      component={TextField}
                      variant="outlined"
                      required
                      fullWidth
                      id="name"
                      label="Booking Title"
                      name="name"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <FormGroup>
                      <Field
                        disablePast
                        component={KeyboardDatePicker}
                        format="DD/MM/yyyy"
                        label="Date"
                        name="bookingDate"
                        inputVariant="outlined"
                        onChange={(date) => {
                          setFieldValue("bookingDate", date, true);
                          handleRequestAvailableRooms({ ...values, bookingDate: date });
                        }}
                        onAccept={(date) => {
                          setFieldError("bookingDate", null);
                        }}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormGroup>
                      <Field
                        component={KeyboardTimePicker}
                        disablePast
                        label="Start Time"
                        name="bookingStartTime"
                        minutesStep={15}
                        ampm={false}
                        inputVariant="outlined"
                        keyboardIcon={<ScheduleIcon />}
                        onChange={(date) => {
                          setFieldValue("bookingStartTime", date, true);
                          setFieldValue("bookingEndTime", moment(date).add(1, "hour"), true);
                          handleRequestAvailableRooms({
                            ...values,
                            bookingStartTime: date,
                            bookingEndTime: moment(date).add(1, "hour"),
                          });
                        }}
                        onAccept={(time) => {
                          setFieldError("bookingStartTime", null);
                        }}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormGroup>
                      <Field
                        component={KeyboardTimePicker}
                        label="End Time"
                        name="bookingEndTime"
                        minutesStep={15}
                        ampm={false}
                        keyboardIcon={<ScheduleIcon />}
                        inputVariant="outlined"
                        onChange={(date) => {
                          setFieldValue("bookingEndTime", moment(date), true);
                          handleRequestAvailableRooms({ ...values, bookingEndTime: date });
                        }}
                        onAccept={(time) => {
                          setFieldError("bookingEndTime", null);
                        }}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel>Meeting Room</FormLabel>
                    {errors.room && touched.room && (
                      <FormHelperText error={true}>{errors.room}</FormHelperText>
                    )}
                  </Grid>
                  {availableRooms.map((availableRoom) => (
                    <Grid key={`room-${availableRoom.room.id}`} item md={6} xs={12}>
                      <RoomButton
                        tooltipText={availableRoom.reason}
                        disabled={!availableRoom.isAvailable}
                        variant={availableRoom.room.id === values.room ? "contained" : "outlined"}
                        color="primary"
                        size="large"
                        fullWidth
                        onClick={(e) => {
                          setFieldValue("room", availableRoom.room.id);
                          setFieldTouched("room", true);
                        }}
                      >
                        {availableRoom.room.name}
                      </RoomButton>
                    </Grid>
                  ))}
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>
                  Close
                </Button>
                <Button type="submit" onClick={submitForm} variant="contained" color="primary">
                  Submit
                </Button>
              </DialogActions>
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default BookingFormModal;
