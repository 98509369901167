import {
  ALL_USERS_REQUEST_SUCCESS,
  ADD_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
  DELETE_USER_SUCCESS,
  MULTIPLE_USERS_DELETE_SUCCESS,
  ADD_COMPANY_USER_SUCCESS,
  ADD_CANTEEN_MANAGER_SUCCESS,
} from "../constants/user";

const initialState = {
  users: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_USERS_REQUEST_SUCCESS:
      return {
        users: action.payload,
      };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload.user],
      };
    case ADD_COMPANY_USER_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload.user],
      };
    case ADD_CANTEEN_MANAGER_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload.user],
      };
    case UPDATE_USER_SUCCESS:
      return {
        users: state.users.map((item) => {
          return action.payload.user.id === item.id ? { ...action.payload.user } : { ...item };
        }),
      };
    case DELETE_USER_SUCCESS:
      return {
        users: state.users.filter((item) => action.payload.id !== item.id),
      };
    case MULTIPLE_USERS_DELETE_SUCCESS:
      return {
        users: state.users.filter((item) => !action.payload.idList.includes(item.id)),
      };
    default:
      return state;
  }
};
export default reducer;
