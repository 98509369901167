import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Role } from "../services/role";

import { addUser, deleteUser, deleteMultiple, updateUser } from "../redux/actions/user.admin";

import { ALL_COMPANIES_REQUEST } from "../redux/constants/company";
import { ALL_USERS_REQUEST } from "../redux/constants/user";

import {
  addUserFormOptions,
  editUserFormOptions,
  editCanteenManagerFormOptions,
} from "../services/formOptions";

import PageTitle from "../components/PageTitle";
import SubmitModal from "../containers/SubmitModal";
import CustomToolbar from "../components/CustomToolbar";
import Loader from "../components/Loader";
import EnlargedImageDialog from "../components/EnlargedImageDialog";
import ClickToEnlarge from "../components/ClickToEnlarge";

import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import { makeStyles, withStyles } from "@material-ui/styles";

import MUIDataTable from "mui-datatables";
import TableTheme from "../utils/TableTheme";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  chips: { margin: theme.spacing(0.5) },
  chipsContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    flexWrap: "wrap",
  },
}));

const initialDialogOptions = {
  title: "",
  descriptionText: "",
  formOptions: {
    initialValues: {},
    fields: [],
    validationSchema: null,
  },
  open: false,
  submitHandler: () => {},
  handleClose: () => {},
};

const ManageUsersPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.admin.users).map((user) => {
    if (!user.company)
      return {
        ...user,
        company: 0,
        companyName: "undefined",
        locations: [],
      };
    return {
      ...user,
      company: user.company.id,
      companyName: user.company.name,
      locations: user.company.locations?.map((location) => location.name),
    };
  });
  const companies = useSelector((state) => state.companies.companies);

  const [currentDialogOptions, setCurrentDialogOptions] = React.useState(initialDialogOptions);

  const handleOpenDialog = ({ title, descriptionText, formOptions, submitHandler }) => {
    setCurrentDialogOptions({
      title: title,
      descriptionText: descriptionText,
      formOptions: formOptions,
      open: true,
      submitHandler: submitHandler,
      handleClose: handleCloseDialog,
    });
  };

  const handleCloseDialog = () => {
    setCurrentDialogOptions(initialDialogOptions);
  };

  const [enlargedImageDialogOpen, setEnlargedImageDialogOpen] = React.useState(false);
  const [currentEnlargedImage, setCurrentEnlargedImage] = React.useState("");

  const handleClickEnlargeImage = (imageLink) => {
    setEnlargedImageDialogOpen(true);
    setCurrentEnlargedImage(imageLink);
  };
  const handleCloseEnlargedImage = () => {
    setEnlargedImageDialogOpen(false);
    setCurrentEnlargedImage("");
  };

  const companiesOptions = companies.map((company) => ({
    name: company.name,
    value: company.id,
  }));

  addUserFormOptions.fields[
    addUserFormOptions.fields.findIndex((field) => field.name === "company")
  ].options = companiesOptions;

  editUserFormOptions.fields[
    editUserFormOptions.fields.findIndex((field) => field.name === "company")
  ].options = companiesOptions;

  const requesting = useSelector((state) => state.request.requesting);

  const columns = [
    {
      name: "id",
      options: {
        filter: false,
        sort: false,
        display: "excluded",
        download: false,
      },
    },
    {
      name: "image",
      label: "Image",
      options: {
        sort: false,
        filter: false,
        download: false,
        customHeadRender: () => (
          <th key={0} style={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }} />
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <ClickToEnlarge imageLink={value} handleClickEnlargeImage={handleClickEnlargeImage} />
            </>
          );
        },
      },
    },
    {
      name: "firstName",
      label: "First Name",
      options: {
        filter: false,
        options: { sortDirection: "desc" },
      },
    },
    {
      name: "lastName",
      label: "Last Name",
      options: {
        filter: false,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
      },
    },
    {
      name: "office",
      label: "Office Location",
      options: {
        filter: false,
      },
    },
    {
      name: "mobile",
      label: "Mobile",
      options: {
        filter: false,
      },
    },
    {
      name: "role",
      label: "Role",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Chip size="small" className={classes.chips} label={Role[value]} />
            </>
          );
        },
        customFilterListOptions: { render: (value) => Role[value] },
        filterOptions: {
          renderValue: (value) => Role[value],
        },
      },
    },
    {
      name: "company",
      label: "Company",
      options: {
        filter: false,
        display: "excluded",
        download: false,
      },
    },

    {
      name: "companyName",
      label: "Company",
    },
    {
      name: "locations",
      label: "Locations",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className={classes.chipsContainer}>
              {value.map((item) => (
                <div key={`location-${item}-user-${tableMeta.rowData[0]}`}>
                  <Chip size="small" className={classes.chips} label={item} />
                </div>
              ))}
            </div>
          );
        },
        sortCompare: (order) => ({ data: locationsList1 }, { data: locationsList2 }) => {
          if (!locationsList1.length && !locationsList2.length) {
            return 0;
          }
          if (!locationsList1.length) {
            return 1 * (order === "asc" ? 1 : -1);
          }
          if (!locationsList2.length) {
            return -1 * (order === "asc" ? 1 : -1);
          }

          if (locationsList1.length === locationsList2.length) {
            return locationsList1[0].localeCompare(locationsList2[0]) * (order === "asc" ? 1 : -1);
          } else if (locationsList1.length > locationsList2.length) {
            return -1 * (order === "asc" ? 1 : -1);
          } else {
            return 1 * (order === "asc" ? 1 : -1);
          }
        },
      },
    },
    {
      name: "id",
      label: "Edit",
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                console.log(tableMeta.rowData[7]);
                if (tableMeta.rowData[7] === "CanteenManager") {
                  handleOpenDialog({
                    title: "Update Canteen Manager",
                    descriptionText: "",
                    formOptions: {
                      ...editCanteenManagerFormOptions,
                      initialValues: {
                        firstName: tableMeta.rowData[2],
                        lastName: tableMeta.rowData[3],
                        email: tableMeta.rowData[4],
                      },
                    },
                    submitHandler: (data) => {
                      updateUserSubmitHandler(value, data);
                    },
                  });
                } else {
                  handleOpenDialog({
                    title: "Update User",
                    descriptionText: "",
                    formOptions: {
                      ...editUserFormOptions,
                      initialValues: {
                        firstName: tableMeta.rowData[2],
                        lastName: tableMeta.rowData[3],
                        email: tableMeta.rowData[4],
                        office: tableMeta.rowData[5],
                        mobile: tableMeta.rowData[6],
                        role: tableMeta.rowData[7],
                        company: tableMeta.rowData[8],
                      },
                    },
                    submitHandler: (data) => {
                      updateUserSubmitHandler(value, data);
                    },
                  });
                }
              }}
            >
              Edit
            </Button>
          );
        },
      },
    },
    {
      name: "id",
      label: "Delete",
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              variant="outlined"
              color="secondary"
              onClick={(e) => {
                e.preventDefault();
                const confirmAction = window.confirm(
                  `Are you sure you want to delete user ${tableMeta.rowData[2]} ${tableMeta.rowData[3]} ? This action can not be reversed.`
                );
                if (confirmAction) deleteUserHandler(value);
              }}
            >
              Delete
            </Button>
          );
        },
      },
    },
  ];

  useEffect(() => {
    dispatch({ type: ALL_USERS_REQUEST });
    dispatch({ type: ALL_COMPANIES_REQUEST });
  }, [dispatch]);
  if (requesting && !currentDialogOptions.open) {
    return <Loader />;
  }
  const handleDeleteSelected = (items) => {
    const confirmAction = window.confirm(
      `Are you sure you want to delete ${items.length} users? This action can not be reversed.`
    );
    if (confirmAction) dispatch(deleteMultiple(items));
  };

  const addUserSubmitHandler = async (data) => {
    dispatch(addUser(data));
  };

  const updateUserSubmitHandler = async (id, data) => {
    dispatch(updateUser(id, data));
  };

  const deleteUserHandler = async (id) => {
    dispatch(deleteUser(id));
  };

  const options = {
    filterType: "dropdown",
    print: false,
    sortOrder: {
      name: "firstName",
      direction: "asc",
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <CustomToolbar
        selectedRows={selectedRows}
        displayData={displayData}
        setSelectedRows={setSelectedRows}
        handleDeleteSelected={handleDeleteSelected}
      />
    ),
  };

  return (
    <>
      <PageTitle
        title={"Manage Users"}
        button={
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleOpenDialog({
                title: "Add new user",
                descriptionText: "",
                formOptions: addUserFormOptions,
                submitHandler: addUserSubmitHandler,
              });
            }}
          >
            Add new user
          </Button>
        }
      />
      <SubmitModal {...currentDialogOptions} />
      <EnlargedImageDialog
        open={enlargedImageDialogOpen}
        handleClose={handleCloseEnlargedImage}
        imageLink={currentEnlargedImage}
      />

      <MuiThemeProvider theme={TableTheme}>
        <MUIDataTable data={data} columns={columns} options={options} />
      </MuiThemeProvider>
    </>
  );
};

export default ManageUsersPage;
