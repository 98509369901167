import React, {useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import moment from "moment";

import {getRoundTime, formValuesToSendValues} from "../utils/timeUtils";
import {REQUEST_MY_LOCATIONS_AND_ROOMS} from "../redux/constants/calendar";
import {
    requestBookings,
    requestAllBookingsForLocation,
    addBooking,
    deleteBooking,
    updateBooking,
    setView,
    setDate,
    requestAvailableRoomsForTimeAndLocation,
    requestAllLimitsForLocation,
} from "../redux/actions/calendar";

import Calendar from "../components/Calendar";
import Box from "@material-ui/core/Box";
import BookingFormModal from "../components/BookingFormModal";
import ViewBookingDetails from "../components/ViewBookingDetails";
import Loader from "../components/Loader";
import Typography from "@material-ui/core/Typography";

import {makeStyles} from "@material-ui/core/styles";
import {useMediaQuery} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    calendarBox: {
        maxWidth: "calc(100%)",
    },
    calDiv: {
        width: "100%",
        overflow: "auto",
        minHeight: "max-content",
        height: "calc(100vh - 100px)",
    },
}));

const initialBookingValues = {
    name: "",
    bookingDate: moment(getRoundTime(new Date())),
    bookingStartTime: moment(getRoundTime(new Date())).add(30, "minutes"),
    bookingEndTime: moment(getRoundTime(new Date())).add(90, "minutes"),
    room: "",
};

const initialViewBookingValues = {
    name: "",
    bookingDate: moment(getRoundTime(new Date())),
    bookingStartTime: moment(getRoundTime(new Date())),
    bookingEndTime: moment(getRoundTime(new Date())),
    user: {firstName: "", lastName: ""},
    room: {name: "", location: {name: ""}},
};

const initialEditBookingValues = {
    id: "",
    name: "",
    bookingDate: moment(getRoundTime(new Date())),
    bookingStartTime: moment(getRoundTime(new Date())),
    bookingEndTime: moment(getRoundTime(new Date())),
    room: "",
};

function MainPage(props) {
    const dispatch = useDispatch();
    const currentLocation = useSelector((state) => state.calendar.currentLocation);
    const view = useSelector((state) => state.calendar.view);
    const views = useSelector((state) => state.calendar.views);
    const date = useSelector((state) => state.calendar.date);
    const currentRoom = useSelector((state) => state.calendar.currentRoom);
    const requesting = useSelector((state) => state.request.requesting);
    const currentBookings = useSelector((state) => state.calendar.bookings);
    const rooms = useSelector((state) => state.calendar.rooms);
    const user = useSelector((state) => state.user);
    const [hasViewBeenSetForMobile, setHasViewBeenSetForMobile] = useState(false);
    const isSmallScreen = useMediaQuery('(max-width:600px)');

    const onViewChange = (view) => {
        dispatch(setView(view));
    };

    const onDateChange = (date) => {
        dispatch(setDate(date));
    };

    const mappedBookings = React.useMemo(() => {
        if (view === "agenda") {
            return currentBookings
                .filter((event) => user.id === event.userId)
                .map((booking, index) => {
                    return {
                        ...booking,
                        bookingStart: moment(booking.bookingStart).toDate(),
                        bookingEnd: moment(booking.bookingEnd).toDate(),
                        resourceId: booking.roomId,
                    };
                });
        } else {
            return currentBookings.map((booking, index) => {
                return {
                    ...booking,
                    bookingStart: moment(booking.bookingStart).toDate(),
                    bookingEnd: moment(booking.bookingEnd).toDate(),
                    resourceId: booking.roomId,
                };
            });
        }
    }, [currentBookings, view, user.id]);

    React.useEffect(() => {
        console.log({isSmallScreen});
        if (isSmallScreen && !hasViewBeenSetForMobile) {
            setHasViewBeenSetForMobile(true)
            dispatch(setView('day'))
        }
    }, [isSmallScreen])

    React.useEffect(() => {
        dispatch({type: REQUEST_MY_LOCATIONS_AND_ROOMS});
    }, [dispatch]);


    React.useEffect(() => {
        if (currentRoom && currentRoom.id) {
            dispatch(requestBookings(currentRoom.id));
        } else if (currentLocation && currentLocation.id) {
            dispatch(requestAllBookingsForLocation(currentLocation.id));
        }
    }, [currentRoom, currentLocation, dispatch]);

    React.useEffect(() => {
        if (currentLocation.id && currentBookings.length) {
            dispatch(requestAllLimitsForLocation(currentLocation.id));
        }
    }, [currentLocation, currentBookings, dispatch]);

    const [createBookingModalOpen, setCreateBookingModalOpen] = React.useState(false);
    const [currentBookingValues, setCurrentBookingValues] = React.useState(initialBookingValues);

    const [updateBookingModalOpen, setUpdateBookingModalOpen] = React.useState(false);
    const [currentlyUpdatedBooking, setCurrentlyUpdatedBooking] = React.useState(
        initialEditBookingValues
    );
    const [viewBookingModalOpen, setViewBookingModalOpen] = React.useState(false);
    const [currentViewBookingValues, setCurrentViewBookingValues] = React.useState(
        initialViewBookingValues
    );

    /// Change view on key press

    const handleViewKeyChange = React.useCallback(
        (event) => {
            if (!viewBookingModalOpen && !createBookingModalOpen && !updateBookingModalOpen) {
                if (event.key.toLowerCase() === "a") {
                    dispatch(setView("agenda"));
                } else if (event.key.toLowerCase() === "d") {
                    dispatch(setView("day"));
                } else if (event.key.toLowerCase() === "w") {
                    dispatch(setView("week"));
                } else if (event.key.toLowerCase() === "m") {
                    dispatch(setView("month"));
                } else if (event.key.toLowerCase() === "x") {
                    dispatch(setView("work_week"));
                } else if (event.key.toLowerCase() === "t") {
                    dispatch(setDate(new Date()));
                }
            }
        },
        [dispatch, viewBookingModalOpen, createBookingModalOpen, updateBookingModalOpen]
    );

    React.useEffect(() => {
        document.addEventListener("keydown", handleViewKeyChange);
        return () => {
            document.removeEventListener("keydown", handleViewKeyChange);
        };
    }, [handleViewKeyChange]);

    const handleOpenBookingModal = (values) => {
        setCurrentBookingValues(values);
        handleRequestAvailableRooms(values);
        setCreateBookingModalOpen(true);
    };

    const handleOpenBookingUpdateModal = (values) => {
        handleCloseBookingDetailsModal();
        const valuesToEdit = {
            id: values.id,
            name: values.name,
            bookingDate: moment(values.bookingStart),
            bookingStartTime: moment(values.bookingStart),
            bookingEndTime: moment(values.bookingEnd),
            room: values.roomId,
        };
        handleRequestAvailableRooms(valuesToEdit);
        setCurrentlyUpdatedBooking(valuesToEdit);
        setUpdateBookingModalOpen(true);
    };

    const handleCloseBookingUpdateModal = () => {
        setUpdateBookingModalOpen(false);
        setCurrentlyUpdatedBooking(initialEditBookingValues);
    };

    const handleRequestAvailableRooms = (bookingInfo) => {
        const sendValues = {
            id: bookingInfo.id,
            location: currentLocation.id,
            bookingStart: formValuesToSendValues(bookingInfo.bookingStartTime, moment),
            bookingEnd: formValuesToSendValues(bookingInfo.bookingEndTime, moment),
        };
        dispatch(requestAvailableRoomsForTimeAndLocation(sendValues));
    };
    const handleCloseBookingModal = () => {
        setCreateBookingModalOpen(false);

        // setInterval(() => {
        setCurrentBookingValues(initialBookingValues);
        // }, 100);
    };

    const handleOpenBookingDetailsModal = (values) => {
        setCurrentViewBookingValues(values);
        setViewBookingModalOpen(true);
    };
    const handleCloseBookingDetailsModal = () => {
        setViewBookingModalOpen(false);
        setCurrentViewBookingValues(initialViewBookingValues);
    };

    const submitBookingHandler = async (data) => {
        dispatch(addBooking({...data}));
        handleCloseBookingModal();
    };

    const submitEditedBookingHandler = (booking) => {
        dispatch(updateBooking(booking.id, booking));
        handleCloseBookingUpdateModal();
    };

    const handleDeleteBooking = (id) => {
        const confirmAction = window.confirm(
            `Are you sure you want to delete the booking ? This action can not be reversed.`
        );
        if (confirmAction) dispatch(deleteBooking(id));
        handleCloseBookingDetailsModal();
    };

    const classes = useStyles();

    if (requesting) {
        return <Loader/>;
    }
    return (
        <div className={classes.root}>
            {currentLocation && rooms.length ? (
                <>
                    <Box className={classes.calendarBox}>
                        <Box className={classes.calDiv}>
                            <Calendar
                                user={user}
                                date={date}
                                setDate={onDateChange}
                                views={views}
                                view={view}
                                onViewChange={onViewChange}
                                events={mappedBookings}
                                setCurrentBookingValues={setCurrentBookingValues}
                                handleOpenBookingModal={handleOpenBookingModal}
                                handleOpenBookingDetailsModal={handleOpenBookingDetailsModal}
                            />
                        </Box>
                    </Box>
                    <BookingFormModal
                        title={"Create booking"}
                        open={createBookingModalOpen}
                        handleClose={handleCloseBookingModal}
                        initialValues={currentBookingValues}
                        handleSubmit={submitBookingHandler}
                    />
                    <BookingFormModal
                        title={"Update booking"}
                        open={updateBookingModalOpen}
                        handleClose={handleCloseBookingUpdateModal}
                        initialValues={currentlyUpdatedBooking}
                        handleSubmit={submitEditedBookingHandler}
                    />
                    <ViewBookingDetails
                        open={viewBookingModalOpen}
                        booking={currentViewBookingValues}
                        handleClose={handleCloseBookingDetailsModal}
                        initialValues={currentViewBookingValues}
                        handleEdit={handleOpenBookingUpdateModal}
                        handleDelete={handleDeleteBooking}
                    />
                </>
            ) : (
                <Typography variant="h2" component="p">
                    No rooms in this locations
                </Typography>
            )}
        </div>
    );
}

export default MainPage;
