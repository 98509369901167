import React from "react";
import PropTypes from "prop-types";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import AvatarImageCropper from "react-avatar-image-cropper";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    height: 100,
    width: 100,
    fontSize: "3rem",
  },
  imageCrop: {
    height: 200,
    width: 200,
    marginBottom: "42px",
  },
}));

const Company = ({ company, companyUpdateHandler, isOwner }) => {
  const classes = useStyles();
  const [editing, setEditing] = React.useState(false);

  return (
    <>
      <Card>
        <CardContent>
          <Box alignItems="center" display="flex" flexDirection="column">
            {editing ? (
              <div className={classes.imageCrop}>
                <AvatarImageCropper
                  maxsize={1024 * 1024 * 20}
                  apply={(picture) => {
                    const data = new FormData();
                    data.append("image", picture);
                    companyUpdateHandler(data);
                  }}
                />
              </div>
            ) : company.image ? (
              <Avatar className={classes.avatar} src={company.image} />
            ) : (
              <Avatar className={classes.avatar}>{company.name.charAt(0)}</Avatar>
            )}
            <Typography color="textPrimary" gutterBottom variant="h3">
              {company.name}
            </Typography>
          </Box>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            fullWidth
            variant="text"
            component="label"
            disabled={!isOwner}
            onClick={(e) => {
              setEditing(!editing);
            }}
          >
            {editing ? "Cancel" : "Update image"}
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

Company.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    image: PropTypes.string,
    created: PropTypes.string,
    updated: PropTypes.string,
    OwnerId: PropTypes.number,
    pinCode: PropTypes.string,
  }),
};

export default Company;
