import {
  ADD_FEATURE,
  MULTIPLE_FEATURES_ACTIVE,
  MULTIPLE_FEATURES_INACTIVE,
  MULTIPLE_FEATURES_DELETE,
  UPDATE_FEATURE,
  DELETE_FEATURE,
} from "../constants/features";

export const addFeature = (feature) => {
  return {
    type: ADD_FEATURE,
    payload: { feature },
  };
};
export const updateFeature = (id, feature) => {
  return {
    type: UPDATE_FEATURE,
    payload: { id, feature },
  };
};

export const setFeatureActive = (id) => {
  return {
    type: UPDATE_FEATURE,
    payload: { id, feature: { isActive: true } },
  };
};

export const setFeatureInactive = (id) => {
  return {
    type: UPDATE_FEATURE,
    payload: { id, feature: { isActive: false } },
  };
};

export const deleteFeature = (id) => {
  return {
    type: DELETE_FEATURE,
    payload: { id },
  };
};
export const setActiveMultiple = (idList) => {
  return {
    type: MULTIPLE_FEATURES_ACTIVE,
    payload: { idList },
  };
};
export const setInactiveMultiple = (idList) => {
  return {
    type: MULTIPLE_FEATURES_INACTIVE,
    payload: { idList },
  };
};

export const deleteMultiple = (idList) => {
  return {
    type: MULTIPLE_FEATURES_DELETE,
    payload: { idList },
  };
};
