import axios from "axios";

import config from "./baseRequestConfig";

const getAllCompaniesUrl = "/api/company/";
const addCompanyUrl = "/api/company/";
const updateCompanyUrl = "/api/company/";
const deleteCompanyUrl = "/api/company/";

const deleteMultipleCompaniesUrl = "/api/company/delete";

export const getAllCompanies = () => {
  const token = config();
  const cfg = {
    headers: { ...axios.defaults.headers, Authorization: token },
  };
  return axios
    .get(getAllCompaniesUrl, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

export const addCompany = (company) => {
  const token = config();
  let cfg;
  try {
    company.get("image");
    cfg = {
      headers: {
        ...axios.defaults.headers,
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    };
  } catch (error) {
    cfg = {
      headers: { ...axios.defaults.headers, Authorization: token },
    };
  }
  return axios
    .post(addCompanyUrl, company, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

export const updateCompany = (id, company) => {
  const token = config();
  let cfg;
  try {
    company.get("image");
    cfg = {
      headers: {
        ...axios.defaults.headers,
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    };
  } catch (error) {
    cfg = {
      headers: { ...axios.defaults.headers, Authorization: token },
    };
  }
  return axios
    .put(updateCompanyUrl + id, company, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

export const deleteCompany = (id) => {
  const token = config();
  const cfg = {
    headers: {
      ...axios.defaults.headers,
      Authorization: token,
    },
  };
  return axios
    .delete(deleteCompanyUrl + id, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

export const deleteMultipleCompanies = (idList) => {
  const token = config();
  const cfg = {
    headers: { ...axios.defaults.headers, Authorization: token },
  };
  return axios
    .post(deleteMultipleCompaniesUrl, { items: idList }, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};
