import axios from "axios";

import config from "./baseRequestConfig";

const storageName = "userData";
const loginUrl = "/api/user/login";
const refreshTokenUrl = "/api/user/refresh-token";
const revokeTokenUrl = "/api/user/revoke-token";

const forgotPasswordUrl = "/api/user/forgot-password";
const validateTokenUrl = "/api/user/validate-token";
const resetPasswordUrl = "/api/user/reset-password";

export const loginApi = (email, password) => {
  return axios
    .post(loginUrl, { email, password })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
};

export const refreshToken = () => {
  return axios
    .post(refreshTokenUrl)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
};

export const revokeToken = () => {
  const token = config();

  const cfg = {
    headers: { ...axios.defaults.headers, Authorization: token },
  };
  return axios
    .post(revokeTokenUrl, null, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
};

export const getLocalAuthToken = () => {
  try {
    return JSON.parse(localStorage.getItem(storageName));
  } catch (error) {
    removeAuthToken();
    return;
  }
};

export const setAuthToken = (token) => {
  localStorage.setItem(storageName, JSON.stringify(token));
};

export const removeAuthToken = () => {
  localStorage.removeItem(storageName);
};

export const sendForgotPasswordRequest = (email) => {
  return axios
    .post(forgotPasswordUrl, email)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
};

export const validateTokenRequest = (token) => {
  return axios
    .post(validateTokenUrl, token)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
};

export const resetPasswordRequest = ({ token, password, confirmPassword }) => {
  return axios
    .post(resetPasswordUrl, { token, password, confirmPassword })
    .then((response) => response.data)
    .catch((error) => {
      throw error.response.data;
    });
};
