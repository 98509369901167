import {
  REQUEST_MY_LOCATIONS_AND_ROOMS_SUCCESS,
  CHANGE_CURRENT_LOCATION,
  CHANGE_CURRENT_ROOM,
  REQUEST_MY_BOOKINGS_SUCCESS,
  ADD_BOOKING_REQUEST_SUCCESS,
  UPDATE_BOOKING_SUCCESS,
  DELETE_BOOKING_SUCCESS,
  SET_VIEW,
  SET_DATE,
  REQUEST_ROOMS_AVAILABILITY_SUCCESS,
  REQUEST_MY_BOOKING_LIMITS_SUCCESS,
} from "../constants/calendar";

const allViews = ["month", "week", "work_week", "day", "agenda"];

const initialState = {
  locations: [],
  rooms: [],
  bookings: [],
  currentLocation: {
    id: undefined,
    name: "",
    description: "",
    image: "",
    rooms: [],
  },
  currentLimits: [],
  currentRoom: null,
  views: allViews,
  view: allViews[0],
  date: new Date(),
  availableRooms: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_MY_LOCATIONS_AND_ROOMS_SUCCESS:
      if (action.payload.locations.length) {
        return {
          ...state,
          locations: action.payload.locations,
          currentLocation: action.payload.locations[0],
          rooms: action.payload.locations[0].rooms,
          currentRoom: null,
        };
      } else {
        return state;
      }
    case REQUEST_MY_BOOKINGS_SUCCESS:
      return {
        ...state,
        bookings: action.payload.bookings,
      };
    case REQUEST_MY_BOOKING_LIMITS_SUCCESS:
      return {
        ...state,
        currentLimits: action.payload.currentLimits,
      };
    case CHANGE_CURRENT_LOCATION:
      return {
        ...state,
        currentLocation: state.locations.find((location) => location.id === action.payload.id),
        rooms: state.locations.find((location) => location.id === action.payload.id).rooms,
        currentRoom: null,
      };
    case CHANGE_CURRENT_ROOM:
      if (action.payload.id) {
        return {
          ...state,
          currentRoom: state.rooms.find((room) => room.id === action.payload.id),
        };
      } else {
        return {
          ...state,
          currentRoom: null,
        };
      }
    case ADD_BOOKING_REQUEST_SUCCESS:
      if (state.currentRoom === null) {
        return {
          ...state,
          bookings: [...state.bookings, action.payload.booking],
        };
      } else if (action.payload.booking.roomId === state.currentRoom.id) {
        // if ()
        return {
          ...state,
          bookings: [...state.bookings, action.payload.booking],
        };
      } else {
        return state;
      }

    case UPDATE_BOOKING_SUCCESS:
      if (state.currentRoom === null) {
        return {
          ...state,
          bookings: state.bookings.map((item) => {
            return action.payload.booking.id === item.id
              ? { ...action.payload.booking }
              : { ...item };
          }),
        };
      } else if (action.payload.booking.roomId === state.currentRoom.id) {
        return {
          ...state,
          bookings: state.bookings.map((item) => {
            return action.payload.booking.id === item.id
              ? { ...action.payload.booking }
              : { ...item };
          }),
        };
      } else {
        return {
          ...state,
          bookings: state.bookings.filter((item) => action.payload.booking.id !== item.id),
        };
      }
    case DELETE_BOOKING_SUCCESS:
      return {
        ...state,
        bookings: state.bookings.filter((item) => action.payload.id !== item.id),
      };
    case SET_VIEW:
      return {
        ...state,
        view: action.payload.view,
      };
    case SET_DATE:
      return {
        ...state,
        date: action.payload.date,
      };
    case REQUEST_ROOMS_AVAILABILITY_SUCCESS:
      return {
        ...state,
        availableRooms: action.payload.availableRooms.sort((a, b) => a.room.id - b.room.id),
      };
    default:
      return state;
  }
};
export default reducer;
