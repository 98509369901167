import React, { useState } from "react";

import moment from "moment";

import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";

import { KeyboardDatePicker } from "@material-ui/pickers";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    "&:first-child": {
      marginLeft: 0,
    },
  },
  button: {
    height: "56px",
    margin: theme.spacing(1),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const CanteenCompanyReportsFilter = ({ canteens, query, setQuery, handleRequest }) => {
  const classes = useStyles();
  const [errors, setErrors] = useState({
    canteenId: "",
    startDate: "",
    endDate: "",
  });

  const handleChange = (event) => {
    setQuery((query) => {
      const updatedQuery = { ...query };
      updatedQuery[event.target.name] = event.target.value;
      return updatedQuery;
    });
  };
  const handleSubmit = () => {
    setErrors((errors) => ({ canteenId: "", startDate: "", endDate: "" }));
    let errors = false;
    if (!query.canteenId) {
      setErrors((errors) => ({ ...errors, canteenId: "Required" }));
      errors = true;
    }
    if (!moment(query.startDate).isValid()) {
      setErrors((errors) => ({ ...errors, startDate: "Invalid Date" }));
      errors = true;
    }
    if (!moment(query.endDate).isValid()) {
      setErrors((errors) => ({ ...errors, endDate: "Invalid Date" }));
      errors = true;
    }
    if (errors) return;
    handleRequest();
  };
  return (
    <div className={classes.container}>
      <FormControl variant="outlined" className={classes.formControl} error={!!errors.canteenId}>
        <InputLabel id="canteen-label">Canteen</InputLabel>
        <Select
          labelId="canteen-label"
          id="canteen-select"
          label="Canteen"
          value={query.canteenId}
          onChange={handleChange}
          name="canteenId"
        >
          {canteens.map((canteen) => (
            <MenuItem key={`canteen-id-${canteen.id}`} value={canteen.id}>
              {canteen.location.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{errors.canteenId}</FormHelperText>
      </FormControl>
      <KeyboardDatePicker
        className={classes.formControl}
        autoOk
        variant="inline"
        inputVariant="outlined"
        label="Start Date"
        format="DD/MM/yyyy"
        value={query.startDate}
        InputAdornmentProps={{ position: "start" }}
        onChange={(date, value) => {
          if (moment(date).isValid()) {
            setQuery((query) => ({ ...query, startDate: date.toDate() }));
          } else {
            setQuery((query) => ({ ...query, startDate: date }));
          }
        }}
        name="startDate"
        error={!!errors.startDate}
        helperText={errors.startDate}
      />
      <KeyboardDatePicker
        className={classes.formControl}
        autoOk
        variant="inline"
        inputVariant="outlined"
        label="End Date"
        format="DD/MM/yyyy"
        value={query.endDate}
        InputAdornmentProps={{ position: "start" }}
        onChange={(date, value) => {
          if (moment(date).isValid()) {
            setQuery((query) => ({ ...query, endDate: date.toDate() }));
          } else {
            setQuery((query) => ({ ...query, endDate: date }));
          }
        }}
        error={!!errors.endDate}
        helperText={errors.endDate}
        name="endDate"
      />
      <Button
        onClick={handleSubmit}
        className={classes.button}
        variant="contained"
        disableElevation
        size="large"
        color="primary"
      >
        Request Data
      </Button>
    </div>
  );
};
export default CanteenCompanyReportsFilter;
