import {
  ALL_CANTEENS_REQUEST_SUCCESS,
  ADD_CANTEEN_SUCCESS,
  UPDATE_CANTEEN_SUCCESS,
  DELETE_CANTEEN_SUCCESS,
  ALL_CANTEEN_ITEMS_REQUEST_SUCCESS,
  ADD_CANTEEN_ITEM_SUCCESS,
  UPDATE_CANTEEN_ITEM_SUCCESS,
  DELETE_CANTEEN_ITEM_SUCCESS,
  QUERY_CANTEEN_REPORT_REQUEST_SUCCESS,
  QUERY_CANTEEN_REPORT_CLEANUP,
  QUERY_COMPANY_CANTEEN_REPORT_REQUEST_SUCCESS,
  QUERY_COMPANY_CANTEEN_REPORT_CLEANUP,
} from "../constants/canteen";

const initialState = {
  canteens: [],
  canteenItems: [],
  canteenReportOrders: {
    orders: {},
    totalPrice: 0,
    totalQuantity: 0,
  },
  companyReportOrders: {
    orders: {},
    total: "",
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_CANTEENS_REQUEST_SUCCESS:
      return {
        ...state,
        canteens: action.payload,
      };
    case ADD_CANTEEN_SUCCESS:
      return {
        ...state,
        canteens: [...state.canteens, action.payload.canteen],
      };
    case UPDATE_CANTEEN_SUCCESS:
      return {
        ...state,
        canteens: state.canteens.map((item) => {
          return action.payload.canteen.id === item.id
            ? { ...action.payload.canteen }
            : { ...item };
        }),
      };
    case DELETE_CANTEEN_SUCCESS:
      return {
        ...state,
        canteens: state.canteens.filter((item) => action.payload.id !== item.id),
      };
    case ALL_CANTEEN_ITEMS_REQUEST_SUCCESS:
      return {
        ...state,
        canteenItems: action.payload,
      };
    case ADD_CANTEEN_ITEM_SUCCESS:
      return {
        ...state,
        canteenItems: [...state.canteenItems, action.payload.item],
      };
    case UPDATE_CANTEEN_ITEM_SUCCESS:
      return {
        ...state,
        canteenItems: state.canteenItems.map((canteenItem) => {
          return action.payload.item.id === canteenItem.id
            ? { ...action.payload.item }
            : { ...canteenItem };
        }),
      };
    case DELETE_CANTEEN_ITEM_SUCCESS:
      return {
        ...state,
        canteenItems: state.canteenItems.filter(
          (canteenItem) => action.payload.id !== canteenItem.id
        ),
      };
    case QUERY_CANTEEN_REPORT_REQUEST_SUCCESS:
      return {
        ...state,
        canteenReportOrders: action.payload,
      };
    case QUERY_CANTEEN_REPORT_CLEANUP:
      return {
        ...state,
        canteenReportOrders: {
          orders: {},
          totalPrice: 0,
          totalQuantity: 0,
        },
      };
    case QUERY_COMPANY_CANTEEN_REPORT_REQUEST_SUCCESS:
      return {
        ...state,
        companyReportOrders: action.payload,
      };
    case QUERY_COMPANY_CANTEEN_REPORT_CLEANUP:
      return {
        ...state,
        companyReportOrders: { orders: "", total: "" },
      };
    default:
      return state;
  }
};
export default reducer;
