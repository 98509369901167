import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export const useTracking = (trackingId) => {
  const history = useHistory();
  const listen = history.listen;
  useEffect(() => {
    const unlisten = listen((location) => {
      if (!window.gtag) return;
      if (!trackingId) {
        console.log("Tracking not enabled, as `trackingId` was not given.");
        return;
      }
      window.gtag("config", trackingId, { page_path: location.pathname });
    });
    return unlisten;
  }, [trackingId, listen]);
};
