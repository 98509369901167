import { take, call, put, race, delay, takeLatest } from "redux-saga/effects";
import { sendNotification } from "../../utils/sendNotification";
import {
  LOGIN_REQUESTING,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  SUBMIT_FORGOT_PASSWORD_REQUESTING,
  SUBMIT_FORGOT_PASSWORD_FAIL,
  SUBMIT_FORGOT_PASSWORD_SUCCESS,
  VALIDATE_RESET_TOKEN_FAIL,
  VALIDATE_RESET_TOKEN_SUCCESS,
  VALIDATE_RESET_TOKEN_REQUESTING,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_REQUESTING,
} from "../constants/auth";

import { REQUEST_FAIL } from "../constants/request";

import { setUser } from "../actions/user";

import { USER_UNSET } from "../constants/user";

import {
  loginApi,
  refreshToken,
  getLocalAuthToken,
  setAuthToken,
  removeAuthToken,
  revokeToken,
  sendForgotPasswordRequest,
  validateTokenRequest,
  resetPasswordRequest,
} from "../../services/auth.service";

const expirationTime = 15 * 60 * 1000;

function logout() {
  revokeToken();
  removeAuthToken();
}

function* loginFlow(credentials) {
  let payload;
  try {
    payload = yield call(loginApi, credentials.email, credentials.password);
    const exp = new Date().valueOf() + expirationTime;

    yield put(setUser({ ...payload, exp }));
    yield put({
      type: LOGIN_SUCCESS,
    });
    sendNotification(`Welcome, ${payload.firstName}!`, "success");
    setAuthToken({
      ...payload,
      exp,
    });

    // history.push("/projects");
  } catch (error) {
    sendNotification(error.message, "error");
    yield put({
      type: LOGIN_FAILURE,
      message: {
        text: error.message,
        severity: "error",
      },
      error: error.message,
    });
  }
  return payload;
}

function* refreshTokenFlow() {
  let payload;
  try {
    payload = yield call(refreshToken);
    const exp = new Date().valueOf() + expirationTime;

    yield put(setUser({ ...payload, exp }));
    yield put({
      type: LOGIN_SUCCESS,
    });

    setAuthToken({
      ...payload,
      exp,
    });
  } catch (error) {
    removeAuthToken();
    yield put({
      type: LOGIN_FAILURE,
      message: {
        text: error.message,
        severity: "error",
      },
      error: error.message,
    });
    yield put({ type: USER_UNSET });
  }
  return payload;
}

export function* loginWatcher() {
  let token = yield call(getLocalAuthToken);
  while (true) {
    if (!token) {
      while (!token) {
        const { payload } = yield take(LOGIN_REQUESTING);
        yield call(loginFlow, payload);
        token = yield call(getLocalAuthToken);
      }
    } else if (token.exp < Date.now().valueOf()) {
      token = null;
      yield call(refreshTokenFlow);
      continue;
    } else {
      yield put(setUser({ ...token }));
      yield put({ type: LOGIN_SUCCESS });
    }

    let userSignedOut;
    while (!userSignedOut) {
      const { expired } = yield race({
        expired: delay(expirationTime),
        signout: take(USER_UNSET),
      });

      // token expired first
      if (expired) {
        yield call(refreshTokenFlow);
        token = yield call(getLocalAuthToken);

        // authorization failed, either by the server or the user signout
        if (!token) {
          userSignedOut = true; // breaks the loop
          token = null;
          yield call(logout);
        }
      }
      // user signed out before token expiration
      else {
        userSignedOut = true; // breaks the loop
        token = null;
        yield call(logout);
      }
    }
  }
}

function* doSubmitForgotPasswordRequest(action) {
  try {
    const payload = yield call(sendForgotPasswordRequest, { email: action.payload.email });
    yield put({
      type: SUBMIT_FORGOT_PASSWORD_SUCCESS,
      payload: { payload },
    });
    yield call(() => {
      action.history.push("/");
    });
    sendNotification(payload.message, "success");
  } catch (error) {
    sendNotification(error.message, "error");
    yield put({
      type: SUBMIT_FORGOT_PASSWORD_FAIL,
    });
  }
}

export function* watchForgotPasswordRequest() {
  yield takeLatest(SUBMIT_FORGOT_PASSWORD_REQUESTING, doSubmitForgotPasswordRequest);
}

function* doValidateResetToken(action) {
  try {
    const payload = yield call(validateTokenRequest, { token: action.payload.token });
    yield put({
      type: VALIDATE_RESET_TOKEN_SUCCESS,
      payload: { payload },
    });
  } catch (error) {
    yield put({
      type: VALIDATE_RESET_TOKEN_FAIL,
      error: error.message,
      payload: {
        message: error.message,
        statusCode: error.status,
      },
    });
  }
}

export function* watchValidateResetToken() {
  yield takeLatest(VALIDATE_RESET_TOKEN_REQUESTING, doValidateResetToken);
}

function* doResetPassword(action) {
  try {
    const payload = yield call(resetPasswordRequest, {
      token: action.payload.token,
      password: action.payload.password,
      confirmPassword: action.payload.confirmPassword,
    });
    yield put({
      type: RESET_PASSWORD_SUCCESS,
      payload: { payload },
    });
    yield call(() => {
      action.history.push("/");
    });
    sendNotification(payload.message, "success");
  } catch (error) {
    sendNotification(error.message, "error");
    yield put({
      type: RESET_PASSWORD_FAIL,
    });
  }
}

export function* watchResetPassword() {
  yield takeLatest(RESET_PASSWORD_REQUESTING, doResetPassword);
}

export function* doLogoutUnauthorizedUser(action) {
  if (action.payload.statusCode === 401) {
    yield put({ type: USER_UNSET });
  }
}

export function* watchUnauthorized() {
  yield takeLatest(REQUEST_FAIL, doLogoutUnauthorizedUser);
}
