import React, { useEffect, useState } from "react";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";

import { ALL_CANTEENS_REQUEST } from "../redux/constants/canteen";
import { queryCompanyCanteenReport, cleanupCompanyReport } from "../redux/actions/canteen";
import PageTitle from "../components/PageTitle";
import Loader from "../components/Loader";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { makeStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import CanteenCompanyReportsFilter from "../containers/CanteenCompanyReportsFilter";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  rightAlignHeader: {
    "& span": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
  },
}));

const ManageCanteensPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const canteens = useSelector((state) => state.canteen.canteens);
  const report = useSelector((state) => state.canteen.companyReportOrders);
  const orders = report.orders;
  const requesting = useSelector((state) => state.request.requesting);

  const data = Object.keys(orders).map((key) => orders[key]);
  const [query, setQuery] = useState({
    canteenId: "",
    startDate: moment().startOf("month"),
    endDate: moment().endOf("month"),
  });
  useEffect(() => {
    dispatch({ type: ALL_CANTEENS_REQUEST });
    return () => {
      dispatch(cleanupCompanyReport());
    };
  }, [dispatch]);

  const handleQueryRequest = () => {
    dispatch(queryCompanyCanteenReport(query));
  };

  const columns = [
    {
      name: "id",
      label: "Order Number",
      options: {},
    },
    {
      name: "canteenOrderItems",
      label: "Quantity",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const totalQuantity = value.reduce(
            (accumulator, currentValue) => currentValue.quantity + accumulator,
            0
          );
          return totalQuantity;
        },
      },
    },
    {
      name: "totalAmount",
      label: "Total Amount",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          className: classes.rightAlignHeader,
        }),
        setCellProps: () => ({
          align: "right",
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value + " kr.";
        },
      },
    },
  ];

  const options = {
    onDownload: (_) => {
      console.log(report);
      console.log(canteens);
      console.log(data);
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const fileName = `canteen-${
        query.canteenId
          ? canteens.find((canteen) => canteen.id === query.canteenId).location.name
          : "undefined"
      }-${moment(query.startDate).format("DD_MM_yyyy")}-${moment(query.endDate).format(
        "DD_MM_yyyy"
      )}`;
      var wsData = [];
      var orderRows = [];
      var i = 2;
      data.forEach((order, index) => {
        orderRows.push(i);
        i++;
        wsData.push({
          "Order Number": `Order Number ${order.id}`,
          Date: "",
          Employee: "",
          Item: "",
          Price: "",
          Quantity: Number(
            order.canteenOrderItems.reduce(
              (accumulator, currentValue) => currentValue.quantity + accumulator,
              0
            )
          ),
          Total: Number(order.totalAmount),
        });
        order.canteenOrderItems.forEach((orderItem) => {
          i++;
          wsData.push({
            "Order Number": "",
            Employee: `${order.user.firstName} ${order.user.lastName}`,
            Date: moment(order.createdAt).format("DD-MM-yyyy"),
            Item: orderItem.canteenItem.name,
            Price: Number(orderItem.canteenItem.price),
            Quantity: Number(orderItem.quantity),
            Total: Number(orderItem.price),
          });
        });
      });

      var workbook = new Workbook();
      var worksheet = workbook.addWorksheet("Canteen Orders");

      worksheet.columns = [
        { header: "-", key: "Order Number", width: 25 },
        { header: "Employee", key: "Employee", width: 20 },
        { header: "Date", key: "Date", width: 10 },
        { header: "Item", key: "Item", width: 10 },
        { header: "Price", key: "Price", width: 10 },
        { header: "Quantity", key: "Quantity", width: 10 },
        { header: "Total", key: "Total", width: 10 },
      ];

      worksheet.addRows(wsData);
      worksheet.getRow(1).border = { bottom: { style: "thin" } };

      orderRows.forEach((rowNr) => {
        worksheet.getRow(rowNr).font = { bold: true };
        ["A", "B", "C", "D", "E", "F", "G", "H"].forEach((col) => {
          let cell = worksheet.getCell(`${col}${rowNr}`);
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF46BDC6" },
          };
        });
      });
      workbook.xlsx.writeBuffer().then((d) => {
        const file = new Blob([d], {
          type: fileType,
        });
        saveAs(file, fileName + fileExtension);
      });

      return false;
    },
    filterType: "textField",
    selectableRows: "none",
    print: false,
    sortOrder: {
      name: "id",
      direction: "asc",
    },
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      return (
        <>
          <tr>
            <td colSpan={6}>
              <TableContainer
                component={Paper}
                style={{
                  display: "flex",
                  width: "100%",
                  background: "#eeeeee",
                  marginBottom: "4px",
                }}
              >
                <Table style={{ minWidth: "650" }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Item</TableCell>
                      <TableCell align="right">Price</TableCell>
                      <TableCell align="right">Quantity</TableCell>
                      <TableCell align="right">Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orders[rowData[0]].canteenOrderItems.map((orderItem, index) => (
                      <TableRow key={`order-item-${orderItem.id}-row${rowData[0]}`}>
                        <TableCell component="th">{index + 1}</TableCell>
                        <TableCell>{orderItem.canteenItem.name}</TableCell>
                        <TableCell align="right">{orderItem.canteenItem.price} kr.</TableCell>
                        <TableCell align="right">{orderItem.quantity}</TableCell>
                        <TableCell align="right">{orderItem.price} kr.</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </td>
          </tr>
        </>
      );
    },
  };

  return (
    <>
      <PageTitle
        title={"Canteen Reports"}
        button={
          report.total ? (
            <Typography variant="h4">{"Total: " + report.total + " kr."}</Typography>
          ) : (
            ""
          )
        }
      />
      <CanteenCompanyReportsFilter
        canteens={canteens}
        query={query}
        setQuery={setQuery}
        handleRequest={handleQueryRequest}
      />
      {requesting ? <Loader /> : <MUIDataTable data={data} columns={columns} options={options} />}
    </>
  );
};

export default ManageCanteensPage;
