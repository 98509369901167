import {
  ADD_RECEPTION,
  UPDATE_RECEPTION,
  DELETE_RECEPTION,
} from "../constants/reception";

export const addReception = (reception) => {
  return {
    type: ADD_RECEPTION,
    payload: { reception },
  };
};
export const updateReception = (id, reception) => {
  return {
    type: UPDATE_RECEPTION,
    payload: { id, reception },
  };
};

export const deleteReception = (id) => {
  return {
    type: DELETE_RECEPTION,
    payload: { id },
  };
};
