import React, {useEffect} from "react";

import {useDispatch, useSelector} from "react-redux";
import {
  acceptCompanyApplication,
  addCompany,
  deleteCompany,
  deleteMultiple,
  rejectCompanyApplication,
  updateCompany
} from "../redux/actions/company";
import {ALL_LOCATIONS_REQUEST} from "../redux/constants/locations";
import {ALL_COMPANIES_REQUEST, ALL_COMPANY_APPLICATIONS_REQUEST} from "../redux/constants/company";

import {addCompanyFormOptions, editCompanyFormOptions} from "../services/formOptions";

import PageTitle from "../components/PageTitle";
import SubmitModal from "../containers/SubmitModal";
import CustomToolbar from "../components/CustomToolbar";
import Loader from "../components/Loader";
import EnlargedImageDialog from "../components/EnlargedImageDialog";
import ClickToEnlarge from "../components/ClickToEnlarge";

import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import {makeStyles} from "@material-ui/styles";

import MUIDataTable from "mui-datatables";
import TableTheme from "../utils/TableTheme";
import {ThemeProvider as MuiThemeProvider} from "@material-ui/core/styles";
import {Badge} from "@material-ui/core";
import CompanyApplicationsModal from "../components/CompanyApplicationsModal";

const useStyles = makeStyles((theme) => ({
  chips: {margin: theme.spacing(0.5)},
}));

const initialDialogOptions = {
  title: "",
  descriptionText: "",
  formOptions: {
    initialValues: {},
    fields: [],
    validationSchema: null,
  },
  open: false,
  submitHandler: () => {},
  handleClose: () => {},
};

const ManageCompaniesPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const locations = useSelector((state) => state.locations.locations);
  const requesting = useSelector((state) => state.request.requesting);
  const data = useSelector((state) => state.companies.companies).map((company) => {
    return {
      ...company,
      locations: company.locations.map((location) => location.id),
      locationsNames: company.locations.map((location) => location.name),
    };
  });
  const companyApplications = useSelector((state) => state.companies.companyApplications)

  const [currentDialogOptions, setCurrentDialogOptions] = React.useState(initialDialogOptions);

  const handleOpenDialog = ({ title, descriptionText, formOptions, submitHandler }) => {
    setCurrentDialogOptions({
      title: title,
      descriptionText: descriptionText,
      formOptions: formOptions,
      open: true,
      submitHandler: submitHandler,
      handleClose: handleCloseDialog,
    });
  };

  const handleCloseDialog = () => {
    setCurrentDialogOptions(initialDialogOptions);
  };

  // Image click to zoom
  const [companyApplicationsDialogOpen, setCompanyApplicationsDialogOpen] = React.useState(false);

  const handleOpenCompanyApplicationsDialog = () => {
    setCompanyApplicationsDialogOpen(true);
  }
  const handleCloseCompanyApplicationsDialog = () => {
    setCompanyApplicationsDialogOpen(false);
  };

  const handleAcceptCompany = (application) => () => {
    handleCloseCompanyApplicationsDialog();
    handleOpenDialog({
      title: "Add Company",
      descriptionText: "",
      formOptions: {
        ...addCompanyFormOptions, initialValues: {
          name: application.name,
          firstName: application.firstName,
          lastName: application.lastName,
          email: application.email,
          office: application.office,
          locations: application.locations,
        },
      },
      submitHandler: acceptCompanyHandler(application.id),
    });
  }

  const handleRejectCompany = (application) => () => {
    const confirmAction = window.confirm(
        `Are you sure you want to reject application for company ${application.name}? This action can not be reversed.`
    );
    if (confirmAction) dispatch(rejectCompanyApplication(application.id));

  }

  const [enlargedImageDialogOpen, setEnlargedImageDialogOpen] = React.useState(false);
  const [currentEnlargedImage, setCurrentEnlargedImage] = React.useState("");
  const handleClickEnlargeImage = (imageLink) => {
    setEnlargedImageDialogOpen(true);
    setCurrentEnlargedImage(imageLink);
  };
  const handleCloseEnlargedImage = () => {
    setEnlargedImageDialogOpen(false);
    setCurrentEnlargedImage("");
  };

  const handleDeleteSelected = (items) => {
    const confirmAction = window.confirm(
        `Are you sure you want to delete ${items.length} companies? This will lead to the deletion of all the users associated with the companies. This action can not be reversed.`
    );
    if (confirmAction) dispatch(deleteMultiple(items));
  };

  const addCompanySubmitHandler = async (data) => {
    dispatch(addCompany(data));
  };

  const acceptCompanyHandler = (applicationId) => (data) => {
    dispatch(addCompany(data));
    dispatch(acceptCompanyApplication(applicationId));
  };

  const updateCompanySubmitHandler = async (id, data) => {
    dispatch(updateCompany(id, data));
  };

  const deleteCompanySubmitHandler = async (id) => {
    dispatch(deleteCompany(id));
  };

  const locationsOptions = locations.map((location) => ({
    name: location.name,
    value: location.id,
  }));
  addCompanyFormOptions.fields[
    addCompanyFormOptions.fields.findIndex((field) => field.name === "locations")
  ].options = locationsOptions;
  editCompanyFormOptions.fields[
    addCompanyFormOptions.fields.findIndex((field) => field.name === "locations")
  ].options = locationsOptions;

  const columns = [
    {
      name: "id",
      options: {
        download: false,
        filter: false,
        sort: false,
        display: "excluded",
      },
    },
    {
      name: "image",
      label: "Image",
      options: {
        download: false,
        sort: false,
        filter: false,
        customHeadRender: () => (
          <th key={"hiddenhead"} style={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }} />
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <ClickToEnlarge imageLink={value} handleClickEnlargeImage={handleClickEnlargeImage} />
            </>
          );
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      name: "office",
      label: "Office Location",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "locations",
      label: "Locations",
      options: {
        download: false,
        filter: false,
        sort: false,
        display: "excluded",
      },
    },
    {
      name: "locationsNames",
      label: "Locations",
      options: {
        sort: false,
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {value.map((item) => (
                <div key={`location-${item}-company-${tableMeta.rowData[0]}`}>
                  <Chip size="small" className={classes.chips} label={item} />
                </div>
              ))}
            </>
          );
        },
      },
    },
    {
      name: "id",
      label: "Edit",
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                handleOpenDialog({
                  title: "Update Company",
                  descriptionText: "",
                  formOptions: {
                    ...editCompanyFormOptions,
                    initialValues: {
                      name: tableMeta.rowData[2],
                      office: tableMeta.rowData[3],
                      locations: tableMeta.rowData[4],
                    },
                  },
                  submitHandler: (data) => {
                    updateCompanySubmitHandler(value, data);
                  },
                });
              }}
            >
              Edit
            </Button>
          );
        },
      },
    },
    {
      name: "id",
      label: "Delete",
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              variant="outlined"
              color="secondary"
              onClick={(e) => {
                e.preventDefault();
                const confirmAction = window.confirm(
                  `Are you sure you want to delete company ${tableMeta.rowData[2]} ? This will lead to the deletion of all the users associated with this company. This action can not be reversed.`
                );
                if (confirmAction) deleteCompanySubmitHandler(value);
              }}
            >
              Delete
            </Button>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    print: false,
    sortOrder: {
      name: "name",
      direction: "asc",
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <CustomToolbar
        selectedRows={selectedRows}
        displayData={displayData}
        setSelectedRows={setSelectedRows}
        handleDeleteSelected={handleDeleteSelected}
      />
    ),
  };

  useEffect(() => {
    dispatch({type: ALL_LOCATIONS_REQUEST});
    dispatch({type: ALL_COMPANIES_REQUEST});
    dispatch({type: ALL_COMPANY_APPLICATIONS_REQUEST});
  }, [dispatch]);

  if (requesting && !currentDialogOptions.open) {
    return <Loader />;
  }

  return (
    <>
      <PageTitle
        title={"Manage Companies"}
        button={
          <>
            <Badge badgeContent={companyApplications.length} color="error" invisible={!companyApplications.length}>
              <Button
                  variant="contained"
                  onClick={handleOpenCompanyApplicationsDialog}
              >
                Company Applications
              </Button>
            </Badge>

            <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleOpenDialog({
                    title: "Add Company",
                    descriptionText: "",
                    formOptions: addCompanyFormOptions,
                    submitHandler: addCompanySubmitHandler,
                  });
                }}
            >
              Create Company
            </Button>
          </>
        }
      />
      <CompanyApplicationsModal open={companyApplicationsDialogOpen} handleClose={handleCloseCompanyApplicationsDialog}
                                applications={companyApplications} acceptHandle={handleAcceptCompany} declineHandler={handleRejectCompany}/>
      <SubmitModal {...currentDialogOptions} />
      <EnlargedImageDialog
          open={enlargedImageDialogOpen}
          handleClose={handleCloseEnlargedImage}
          imageLink={currentEnlargedImage}
      />
      <MuiThemeProvider theme={TableTheme}>
        <MUIDataTable data={data} columns={columns} options={options}/>
      </MuiThemeProvider>
    </>
  );
};

export default ManageCompaniesPage;
