import { takeLatest, call, put } from "redux-saga/effects";
import { sendNotification } from "../../utils/sendNotification";

import {
  ALL_LOCATIONS_REQUEST,
  ALL_LOCATIONS_REQUEST_SUCCESS,
  ALL_LOCATIONS_REQUEST_FAILURE,
  ADD_LOCATION,
  ADD_LOCATION_SUCCESS,
  UPDATE_LOCATION,
  UPDATE_LOCATION_SUCCESS,
  DELETE_LOCATION,
  DELETE_LOCATION_SUCCESS,
  MULTIPLE_LOCATIONS_ACTIVE,
  MULTIPLE_LOCATIONS_INACTIVE,
  MULTIPLE_LOCATIONS_DELETE,
  MULTIPLE_LOCATIONS_ACTIVE_SUCCESS,
  MULTIPLE_LOCATIONS_INACTIVE_SUCCESS,
  MULTIPLE_LOCATIONS_DELETE_SUCCESS,
} from "../constants/locations";

import {
  REQUEST_STARTED,
  REQUEST_FAIL,
  REQUEST_SUCCESS,
  REQUEST_FINISHED,
} from "../constants/request";

import {
  getAllLocations,
  addLocation,
  updateLocation,
  deleteLocation,
  deleteMultipleLocations,
  setMultipleLocationsInactive,
  setMultipleLocationsActive,
} from "../../services/locations.service";

export function* doRequestAllLocations(action) {
  try {
    yield put({ type: REQUEST_STARTED });

    const payload = yield call(getAllLocations);
    yield put({ type: ALL_LOCATIONS_REQUEST_SUCCESS, payload });
    yield put({ type: REQUEST_SUCCESS });
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
    yield put({ type: ALL_LOCATIONS_REQUEST_FAILURE });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchRequestAllLocations() {
  yield takeLatest(ALL_LOCATIONS_REQUEST, doRequestAllLocations);
}

export function* doAddLocation(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    const location = yield call(addLocation, payload.location);
    yield put({ type: ADD_LOCATION_SUCCESS, payload: { location } });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Location successfully created", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchAddLocation() {
  yield takeLatest(ADD_LOCATION, doAddLocation);
}

export function* doUpdateLocation(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    const location = yield call(updateLocation, payload.id, payload.location);
    yield put({ type: UPDATE_LOCATION_SUCCESS, payload: { location } });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Location successfully updated", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchUpdateLocation() {
  yield takeLatest(UPDATE_LOCATION, doUpdateLocation);
}

export function* doDeleteLocation(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    yield call(deleteLocation, payload.id);
    yield put({ type: DELETE_LOCATION_SUCCESS, payload: { id: payload.id } });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Location successfully deleted", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchDeleteLocation() {
  yield takeLatest(DELETE_LOCATION, doDeleteLocation);
}

export function* doMultipleLocationsActive(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    yield call(setMultipleLocationsActive, payload.idList);
    yield put({ type: MULTIPLE_LOCATIONS_ACTIVE_SUCCESS, payload });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Locations successfully updated", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchMultipleLocationsActive() {
  yield takeLatest(MULTIPLE_LOCATIONS_ACTIVE, doMultipleLocationsActive);
}

export function* doMultipleLocationsInactive(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    yield call(setMultipleLocationsInactive, payload.idList);
    yield put({ type: MULTIPLE_LOCATIONS_INACTIVE_SUCCESS, payload });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Locations successfully updated", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchMultipleLocationsInactive() {
  yield takeLatest(MULTIPLE_LOCATIONS_INACTIVE, doMultipleLocationsInactive);
}
export function* doDeleteMultipleLocations(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    yield call(deleteMultipleLocations, payload.idList);
    yield put({ type: MULTIPLE_LOCATIONS_DELETE_SUCCESS, payload });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Locations successfully deleted", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchDeleteMultipleLocations() {
  yield takeLatest(MULTIPLE_LOCATIONS_DELETE, doDeleteMultipleLocations);
}
