import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  addFeature,
  setFeatureActive,
  setFeatureInactive,
  deleteFeature,
  setActiveMultiple,
  setInactiveMultiple,
  deleteMultiple,
  updateFeature,
} from "../redux/actions/features";

import { ALL_FEATURES_REQUEST } from "../redux/constants/features";

import { addFeatureFormOptions, editFeatureFormOptions } from "../services/formOptions";

import PageTitle from "../components/PageTitle";
import SubmitModal from "../containers/SubmitModal";
import CustomToolbar from "../components/CustomToolbar";
import Loader from "../components/Loader";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";

import MUIDataTable from "mui-datatables";
import TableTheme from "../utils/TableTheme";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";

const initialDialogOptions = {
  title: "",
  descriptionText: "",
  formOptions: {
    initialValues: {},
    fields: [],
    validationSchema: null,
  },
  open: false,
  submitHandler: () => {},
  handleClose: () => {},
};

const ManageFeaturesPage = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.features.features);
  const requesting = useSelector((state) => state.request.requesting);

  const [currentDialogOptions, setCurrentDialogOptions] = React.useState(initialDialogOptions);

  const handleOpenDialog = ({ title, descriptionText, formOptions, submitHandler }) => {
    setCurrentDialogOptions({
      title: title,
      descriptionText: descriptionText,
      formOptions: formOptions,
      open: true,
      submitHandler: submitHandler,
      handleClose: handleCloseDialog,
    });
  };
  const handleCloseDialog = () => {
    setCurrentDialogOptions(initialDialogOptions);
  };
  const columns = [
    {
      name: "id",
      options: {
        filter: false,
        sort: false,
        display: "excluded",
        download: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
      },
    },
    {
      name: "isActive",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <FormControlLabel
              label={value ? "Active" : "Inactive"}
              value={value ? "Active" : "Inactive"}
              control={
                <Switch color="primary" checked={value} value={value ? "Active" : "Inactive"} />
              }
              onChange={(event) => {
                const id = tableMeta.rowData[0];
                if (event.target.value === "Active") {
                  dispatch(setFeatureInactive(id));
                } else {
                  dispatch(setFeatureActive(id));
                }
              }}
            />
          );
        },
      },
    },
    {
      name: "id",
      label: "Edit",
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                handleOpenDialog({
                  title: "Edit Feature",
                  descriptionText: "",
                  formOptions: {
                    ...editFeatureFormOptions,
                    initialValues: {
                      name: tableMeta.rowData[1],
                    },
                  },
                  submitHandler: (data) => {
                    updateFeatureSubmitHandler(value, data);
                  },
                });
              }}
            >
              Edit
            </Button>
          );
        },
      },
    },
    {
      name: "id",
      label: "Delete",
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              variant="outlined"
              color="secondary"
              onClick={(e) => {
                e.preventDefault();
                const confirmAction = window.confirm(
                  `Are you sure you want to delete feature ${tableMeta.rowData[2]} ? This action can not be reversed.`
                );
                if (confirmAction) deleteFeatureHandler(value);
              }}
            >
              Delete
            </Button>
          );
        },
      },
    },
  ];

  useEffect(() => {
    dispatch({ type: ALL_FEATURES_REQUEST });
  }, [dispatch]);

  if (requesting && !currentDialogOptions.open) {
    return <Loader />;
  }
  const handleMarkSelectedAsActive = (items) => {
    dispatch(setActiveMultiple(items));
  };
  const handleMarkSelectedAsInactive = (items) => {
    dispatch(setInactiveMultiple(items));
  };
  const handleDeleteSelected = (items) => {
    const confirmAction = window.confirm(
      `Are you sure you want to delete ${items.length} features? This action can not be reversed.`
    );
    if (confirmAction) dispatch(deleteMultiple(items));
  };

  const addFeatureSubmitHandler = async (data) => {
    dispatch(addFeature(data));
  };
  const updateFeatureSubmitHandler = async (id, data) => {
    dispatch(updateFeature(id, data));
  };

  const deleteFeatureHandler = async (id) => {
    dispatch(deleteFeature(id));
  };

  const options = {
    filterType: "checkbox",
    print: false,
    sortOrder: {
      name: "name",
      direction: "asc",
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <CustomToolbar
        selectedRows={selectedRows}
        displayData={displayData}
        setSelectedRows={setSelectedRows}
        handleMarkSelectedAsActive={handleMarkSelectedAsActive}
        handleMarkSelectedAsInactive={handleMarkSelectedAsInactive}
        handleDeleteSelected={handleDeleteSelected}
      />
    ),
  };

  return (
    <>
      <PageTitle
        title={"Manage Features"}
        button={
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleOpenDialog({
                title: "Add new feature",
                descriptionText: "",
                formOptions: addFeatureFormOptions,
                submitHandler: addFeatureSubmitHandler,
              });
            }}
          >
            Add new feature
          </Button>
        }
      />
      <SubmitModal {...currentDialogOptions} />
      <MuiThemeProvider theme={TableTheme}>
        <MUIDataTable data={data} columns={columns} options={options} />
      </MuiThemeProvider>
    </>
  );
};

export default ManageFeaturesPage;
