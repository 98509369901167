import { getLocalAuthToken } from "./auth.service";
import _ from "lodash";

const getBaseRequestConfig = () => {
  const state = getLocalAuthToken();

  var authHeader;

  if (state && _.has(state, "jwtToken")) {
    authHeader = `Bearer ${state.jwtToken}`;
  }

  return authHeader;
};
export default getBaseRequestConfig;
