import { takeLatest, call, put } from "redux-saga/effects";
import { sendNotification } from "../../utils/sendNotification";

import {
  ALL_ROOMS_REQUEST,
  ALL_ROOMS_REQUEST_SUCCESS,
  ALL_ROOMS_REQUEST_FAILURE,
  ADD_ROOM,
  ADD_ROOM_SUCCESS,
  UPDATE_ROOM,
  UPDATE_ROOM_SUCCESS,
  DELETE_ROOM,
  DELETE_ROOM_SUCCESS,
  MULTIPLE_ROOMS_ACTIVE,
  MULTIPLE_ROOMS_INACTIVE,
  MULTIPLE_ROOMS_DELETE,
  MULTIPLE_ROOMS_ACTIVE_SUCCESS,
  MULTIPLE_ROOMS_INACTIVE_SUCCESS,
  MULTIPLE_ROOMS_DELETE_SUCCESS,
} from "../constants/rooms";

import {
  REQUEST_STARTED,
  REQUEST_FAIL,
  REQUEST_SUCCESS,
  REQUEST_FINISHED,
} from "../constants/request";

import {
  getAllRooms,
  addRoom,
  updateRoom,
  deleteRoom,
  deleteMultipleRooms,
  setMultipleRoomsInactive,
  setMultipleRoomsActive,
} from "../../services/rooms.service";

export function* doRequestAllRooms(action) {
  try {
    yield put({ type: REQUEST_STARTED });

    const payload = yield call(getAllRooms);
    yield put({ type: ALL_ROOMS_REQUEST_SUCCESS, payload });
    yield put({ type: REQUEST_SUCCESS });
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
    yield put({ type: ALL_ROOMS_REQUEST_FAILURE });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchRequestAllRooms() {
  yield takeLatest(ALL_ROOMS_REQUEST, doRequestAllRooms);
}

export function* doAddRoom(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    const room = yield call(addRoom, payload.room);
    yield put({ type: ADD_ROOM_SUCCESS, payload: { room } });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Room successfully created", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchAddRoom() {
  yield takeLatest(ADD_ROOM, doAddRoom);
}

export function* doUpdateRoom(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    const room = yield call(updateRoom, payload.id, payload.room);
    yield put({ type: UPDATE_ROOM_SUCCESS, payload: { room } });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Room successfully updated", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchUpdateRoom() {
  yield takeLatest(UPDATE_ROOM, doUpdateRoom);
}

export function* doDeleteRoom(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    yield call(deleteRoom, payload.id);
    yield put({ type: DELETE_ROOM_SUCCESS, payload: { id: payload.id } });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Room successfully deleted", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchDeleteRoom() {
  yield takeLatest(DELETE_ROOM, doDeleteRoom);
}

export function* doMultipleRoomsActive(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    yield call(setMultipleRoomsActive, payload.idList);
    yield put({ type: MULTIPLE_ROOMS_ACTIVE_SUCCESS, payload });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Rooms successfully updated", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchMultipleRoomsActive() {
  yield takeLatest(MULTIPLE_ROOMS_ACTIVE, doMultipleRoomsActive);
}

export function* doMultipleRoomsInactive(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    yield call(setMultipleRoomsInactive, payload.idList);
    yield put({ type: MULTIPLE_ROOMS_INACTIVE_SUCCESS, payload });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Rooms successfully updated", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchMultipleRoomsInactive() {
  yield takeLatest(MULTIPLE_ROOMS_INACTIVE, doMultipleRoomsInactive);
}
export function* doDeleteMultipleRooms(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    yield call(deleteMultipleRooms, payload.idList);
    yield put({ type: MULTIPLE_ROOMS_DELETE_SUCCESS, payload });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Rooms successfully deleted", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchDeleteMultipleRooms() {
  yield takeLatest(MULTIPLE_ROOMS_DELETE, doDeleteMultipleRooms);
}
