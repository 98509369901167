import {
  REQUEST_STARTED,
  REQUEST_FINISHED,
  REQUEST_FAIL,
  REQUEST_SUCCESS,
  CLEAR_REQUEST_INFO,
} from "../constants/request";

const initialState = {
  requesting: false,
  error: {
    message: "",
    statusCode: "",
  },
  confirm: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_STARTED:
      return {
        requesting: true,
        error: {
          message: "",
          statusCode: "",
        },
        confirm: false,
      };
    case REQUEST_SUCCESS:
      return {
        requesting: false,
        error: {
          message: "",
          statusCode: "",
        },
        confirm: true,
      };
    case REQUEST_FAIL:
      return {
        requesting: false,
        error: {
          message: action.payload.message,
          statusCode: action.payload.statusCode,
        },
        confirm: false,
      };
    case REQUEST_FINISHED:
      return {
        ...state,
        requesting: false,
      };
    case CLEAR_REQUEST_INFO:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
export default reducer;
