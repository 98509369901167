import {
  ADD_USER,
  MULTIPLE_USERS_DELETE,
  UPDATE_USER,
  DELETE_USER,
  ADD_COMPANY_USER,
  ADD_CANTEEN_MANAGER,
} from "../constants/user";

export const addUser = (user) => {
  return {
    type: ADD_USER,
    payload: { user },
  };
};
export const updateUser = (id, user) => {
  return {
    type: UPDATE_USER,
    payload: { id, user },
  };
};

export const deleteUser = (id) => {
  return {
    type: DELETE_USER,
    payload: { id },
  };
};

export const deleteMultiple = (idList) => {
  return {
    type: MULTIPLE_USERS_DELETE,
    payload: { idList },
  };
};

export const addCompanyUser = (user) => {
  return {
    type: ADD_COMPANY_USER,
    payload: { user },
  };
};

export const addCanteenManager = (user) => {
  return {
    type: ADD_CANTEEN_MANAGER,
    payload: { user },
  };
};
