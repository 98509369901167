import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import TopbarComponent from "../components/TopbarComponent";
import SidebarComponent from "../components/SidebarComponent";
import MenuItems from "./MenuItems";

import Paper from "@material-ui/core/Paper";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    maxWidth: "100vw",
    overflowX: "hidden",
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    width: `calc(100vw - 240px)`,
    minHeight: "100vh",
  },
  contentShift: {
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  mainContent: {
    padding: theme.spacing(1),
    marginBottom: "60px",
  },

  toastsContainer: {
    width: 400,
    marginTop: theme.spacing(6),
    right: 0,
  },
  notificationCloseButton: {
    position: "absolute",
    right: theme.spacing(2),
  },
  progress: {
    visibility: "hidden",
  },
}));

function MainLayout({ paper = false, topbarContent, ...props }) {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <div className={classes.root}>
        <TopbarComponent handleDrawerToggle={handleDrawerToggle} topbarContent={topbarContent} />
        <SidebarComponent mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle}>
          <MenuItems />
        </SidebarComponent>

        <main className={classes.content}>
          <div className={classes.toolbar} />
          {paper ? (
            <Paper className={classes.mainContent} elevation={2}>
              {props.children}
            </Paper>
          ) : (
            <div className={classes.mainContent}>{props.children}</div>
          )}
        </main>
      </div>
    </>
  );
}

export default MainLayout;
