import axios from "axios";

import config from "./baseRequestConfig";

const getAllUsersUrl = "/api/user/";
const updateUserUrl = "/api/user/";
const deleteUserUrl = "/api/user/";
const deleteMultipleUsersUrl = "/api/user/delete";
const addUserUrl = "/api/user/";
const addCompanyUserUrl = "/api/user/register";
const addCanteenManagerUrl = "/api/user/register-canteen-manager";

export const getAllUsers = () => {
  const token = config();
  const cfg = {
    headers: { ...axios.defaults.headers, Authorization: token },
  };
  return axios
    .get(getAllUsersUrl, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};
export const addUser = (user) => {
  const token = config();
  let cfg;
  try {
    user.get("image");
    cfg = {
      headers: {
        ...axios.defaults.headers,
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    };
  } catch (error) {
    cfg = {
      headers: { ...axios.defaults.headers, Authorization: token },
    };
  }
  return axios
    .post(addUserUrl, user, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};
export const addCompanyUser = (user) => {
  const token = config();
  const cfg = {
    headers: { ...axios.defaults.headers, Authorization: token },
  };
  return axios
    .post(addCompanyUserUrl, user, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};
export const addCanteenManagerUser = (user) => {
  const token = config();
  const cfg = {
    headers: { ...axios.defaults.headers, Authorization: token },
  };
  return axios
    .post(addCanteenManagerUrl, user, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

export const updateUser = (id, company) => {
  const token = config();
  let cfg;
  try {
    company.get("image");
    cfg = {
      headers: {
        ...axios.defaults.headers,
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    };
  } catch (error) {
    cfg = {
      headers: { ...axios.defaults.headers, Authorization: token },
    };
  }
  return axios
    .put(updateUserUrl + id, company, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

export const deleteUser = (id) => {
  const token = config();
  const cfg = {
    headers: {
      ...axios.defaults.headers,
      Authorization: token,
    },
  };
  return axios
    .delete(deleteUserUrl + id, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

export const deleteMultipleUsers = (idList) => {
  const token = config();
  const cfg = {
    headers: { ...axios.defaults.headers, Authorization: token },
  };
  return axios
    .post(deleteMultipleUsersUrl, { items: idList }, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};
