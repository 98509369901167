import React, { useState } from "react";

import { Formik, Field, FieldArray, Form } from "formik";
import { TextField, Switch, SimpleFileUpload, Select } from "formik-material-ui";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import { InputAdornment, IconButton } from "@material-ui/core";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  padField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const DynamicField = ({
  type,
  id,
  label,
  name,
  placeholder,
  required,
  options,
  setFieldValue,
  setFieldError,
  values,
  errors,
}) => {
  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  switch (type) {
    case "password":
      return (
        <Field
          component={TextField}
          variant="outlined"
          margin="normal"
          required={required}
          fullWidth
          id={id}
          type={showPassword ? "text" : "password"}
          label={label}
          name={name}
          autoComplete={name}
          InputProps={{
            placeholder,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      );
    case "text":
      return (
        <Field
          component={TextField}
          variant="outlined"
          margin="normal"
          required={required}
          fullWidth
          id={id}
          label={label}
          name={name}
          autoComplete={name}
          InputProps={{
            placeholder,
          }}
        />
      );
    case "number":
      return (
        <Field
          component={TextField}
          type="number"
          min={0}
          variant="outlined"
          margin="normal"
          required={required}
          fullWidth
          id={id}
          label={label}
          name={name}
          autoComplete={name}
        />
      );
    case "textarea":
      return (
        <Field
          component={TextField}
          variant="outlined"
          margin="normal"
          required={required}
          fullWidth
          id={id}
          label={label}
          name={name}
          autoComplete={name}
          multiline
          rowsMax={4}
          rows={4}
        />
      );
    case "checkbox":
      return (
        <FormGroup className={classes.padField}>
          <FormControlLabel
            control={
              <Field
                component={Switch}
                type="checkbox"
                variant="outlined"
                margin="normal"
                id={id}
                name={name}
              />
            }
            label={label}
          />
        </FormGroup>
      );
    case "image":
      return (
        <div className={classes.padField}>
          <Field
            margin="normal"
            component={SimpleFileUpload}
            id={id}
            name={name}
            label={label}
            InputProps={{
              disableUnderline: true,
              onChange: (e) => {
                setFieldValue("image", e.currentTarget.files[0]);
              },
            }}
            InputLabelProps={{
              error: !!errors[name],
            }}
            FormControlProps={{}}
          />
        </div>
      );

    case "select":
      return (
        <FormGroup className={classes.padField}>
          <FormControl error={!!errors[name]}>
            <InputLabel id={id + "-label"}>{label}</InputLabel>
            <Field
              name={name}
              type="text"
              component={Select}
              inputProps={{
                name: name,
                labelId: id + "-label",
                id: id,
                label: label,
              }}
            >
              {options.map((val) => (
                <MenuItem key={val.value + "-" + val.name} value={val.value}>
                  {val.name}
                </MenuItem>
              ))}
            </Field>
            <FormHelperText>{!!errors[name] ? errors[name] : ""}</FormHelperText>
          </FormControl>
        </FormGroup>
      );
    case "multiple-select-checkboxes":
      return (
        <>
          <FieldArray
            name={name}
            render={(arrayHelpers) => (
              <div>
                <FormControl error={!!errors[name]}>
                  <FormLabel component="legend">{label}</FormLabel>
                  <FormGroup>
                    {options.map((option) => {
                      return (
                        <FormControlLabel
                          key={`option-${option.value}-${option.name}`}
                          control={
                            <Checkbox
                              name={name}
                              value={option.value}
                              checked={values[name].includes(option.value)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  arrayHelpers.push(option.value);
                                } else {
                                  const idx = values[name].indexOf(option.value);
                                  arrayHelpers.remove(idx);
                                }
                              }}
                              color="primary"
                            />
                          }
                          label={option.name}
                        />
                      );
                    })}
                  </FormGroup>
                  <FormHelperText>{!!errors[name] ? errors[name] : ""}</FormHelperText>
                </FormControl>
              </div>
            )}
          />
        </>
      );
    default:
      return <div>UnsupportedField</div>;
  }
};

const DynamicForm = ({
  descriptionText,
  handleClose,
  validationSchema,
  fields,
  submitHandler,
  requesting,
  initialValues,
}) => {
  return (
    <>
      <Formik
        initialValues={initialValues}
        validateOnChange={true}
        validationSchema={validationSchema}
        validate={(values) => {
          const errors = {};
          if (values.image) {
            if (values.image.size >= 20 * 1024 * 1024) {
              errors.image = "Image too large. Maximum size is 20 MB";
            } else if (!["image/png", "image/jpeg"].includes(values.image.type)) {
              errors.image = "Invalid file type. Must be JPG or PNG";
            }
          }
          return errors;
        }}
        onSubmit={(values, actions) => {
          if (!values.image) {
            actions.setSubmitting(false);
            return submitHandler(values);
          }
          const data = new FormData();
          Object.keys(values).map(function (key, index) {
            if (Array.isArray(values[key])) {
              data.append(key, JSON.stringify(values[key]));
            } else {
              data.append(key, values[key]);
            }
            return null;
          });
          submitHandler(data);
          actions.setSubmitting(false);
        }}
      >
        {({
          submitForm,
          setFieldValue,
          values,
          getFieldHelpers,
          errors,
          isSubmitting,
          setSubmitting,
          setFieldError,
        }) => (
          <Form>
            <DialogContent>
              <DialogContentText>{descriptionText ? descriptionText : ""}</DialogContentText>
              {fields.map((field) => (
                <DynamicField
                  key={field.name}
                  setFieldValue={setFieldValue}
                  setFieldError={setFieldError}
                  values={values}
                  errors={errors}
                  {...field}
                />
              ))}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="secondary">
                Cancel
              </Button>
              <Button type="submit" disabled={requesting} variant="contained" color="primary">
                Submit
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default DynamicForm;
