import axios from "axios";

import config from "./baseRequestConfig";

const getAllLocationsUrl = "/api/location/";
const addLocationUrl = "/api/location/";
const updateLocationUrl = "/api/location/";
const deleteLocationUrl = "/api/location/";

const setMultipleLocationsActiveUrl = "/api/location/set-active";
const setMultipleLocationsInactiveUrl = "/api/location/set-inactive";
const deleteMultipleLocationsUrl = "/api/location/delete";

export const getAllLocations = () => {
  const token = config();
  const cfg = {
    headers: { ...axios.defaults.headers, Authorization: token },
  };
  return axios
    .get(getAllLocationsUrl, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

export const addLocation = (location) => {
  const token = config();
  let cfg;
  try {
    location.get("image");
    cfg = {
      headers: {
        ...axios.defaults.headers,
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    };
  } catch (error) {
    cfg = {
      headers: { ...axios.defaults.headers, Authorization: token },
    };
  }
  return axios
    .post(addLocationUrl, location, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

export const updateLocation = (id, location) => {
  const token = config();
  let cfg;
  try {
    location.get("image");
    cfg = {
      headers: {
        ...axios.defaults.headers,
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    };
  } catch (error) {
    cfg = {
      headers: { ...axios.defaults.headers, Authorization: token },
    };
  }
  return axios
    .put(updateLocationUrl + id, location, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

export const deleteLocation = (id) => {
  const token = config();
  const cfg = {
    headers: {
      ...axios.defaults.headers,
      Authorization: token,
    },
  };
  return axios
    .delete(deleteLocationUrl + id, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

export const setMultipleLocationsActive = (idList) => {
  const token = config();
  const cfg = {
    headers: { ...axios.defaults.headers, Authorization: token },
  };
  return axios
    .put(setMultipleLocationsActiveUrl, { items: idList }, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

export const setMultipleLocationsInactive = (idList) => {
  const token = config();
  const cfg = {
    headers: { ...axios.defaults.headers, Authorization: token },
  };
  return axios
    .put(setMultipleLocationsInactiveUrl, { items: idList }, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

export const deleteMultipleLocations = (idList) => {
  const token = config();
  const cfg = {
    headers: { ...axios.defaults.headers, Authorization: token },
  };
  return axios
    .post(deleteMultipleLocationsUrl, { items: idList }, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};
