import { takeLatest, call, put } from "redux-saga/effects";
import { sendNotification } from "../../utils/sendNotification";

import {
  ALL_USERS_REQUEST,
  ALL_USERS_REQUEST_FAILURE,
  ALL_USERS_REQUEST_SUCCESS,
  ADD_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
  DELETE_USER_SUCCESS,
  MULTIPLE_USERS_DELETE,
  ADD_USER,
  UPDATE_USER,
  DELETE_USER,
  MULTIPLE_USERS_DELETE_SUCCESS,
  ADD_COMPANY_USER,
  ADD_COMPANY_USER_SUCCESS,
  ADD_CANTEEN_MANAGER,
  ADD_CANTEEN_MANAGER_SUCCESS,
} from "../constants/user";

import { ALL_CANTEENS_REQUEST } from "../constants/canteen";
import {
  REQUEST_STARTED,
  REQUEST_FAIL,
  REQUEST_SUCCESS,
  REQUEST_FINISHED,
} from "../constants/request";

import {
  getAllUsers,
  addUser,
  updateUser,
  deleteUser,
  deleteMultipleUsers,
  addCompanyUser,
  addCanteenManagerUser,
} from "../../services/user.admin.service";

export function* doRequestAllUsers(action) {
  try {
    yield put({ type: REQUEST_STARTED });

    const payload = yield call(getAllUsers);
    yield put({ type: ALL_USERS_REQUEST_SUCCESS, payload });
    yield put({ type: REQUEST_SUCCESS });
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
    yield put({ type: ALL_USERS_REQUEST_FAILURE });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchRequestAllUsers() {
  yield takeLatest(ALL_USERS_REQUEST, doRequestAllUsers);
}

export function* doAddUser(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    const user = yield call(addUser, payload.user);
    yield put({ type: ADD_USER_SUCCESS, payload: { user } });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("User successfully created", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchAddUser() {
  yield takeLatest(ADD_USER, doAddUser);
}

export function* doAddCompanyUser(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    const user = yield call(addCompanyUser, payload.user);
    yield put({ type: ADD_COMPANY_USER_SUCCESS, payload: { user } });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("User successfully created", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchAddCompanyUser() {
  yield takeLatest(ADD_COMPANY_USER, doAddCompanyUser);
}

export function* doAddCanteenManager(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    const user = yield call(addCanteenManagerUser, payload.user);
    yield put({ type: ADD_CANTEEN_MANAGER_SUCCESS, payload: { user } });
    yield put({ type: ALL_CANTEENS_REQUEST });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Canteen manager successfully created", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchAddCanteenManager() {
  yield takeLatest(ADD_CANTEEN_MANAGER, doAddCanteenManager);
}

export function* doUpdateUser(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    const user = yield call(updateUser, payload.id, payload.user);
    yield put({ type: UPDATE_USER_SUCCESS, payload: { user } });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("User successfully updated", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchUpdateUser() {
  yield takeLatest(UPDATE_USER, doUpdateUser);
}

export function* doDeleteUser(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    yield call(deleteUser, payload.id);
    yield put({ type: DELETE_USER_SUCCESS, payload: { id: payload.id } });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("User successfully deleted", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchDeleteUser() {
  yield takeLatest(DELETE_USER, doDeleteUser);
}

export function* doDeleteMultipleUsers(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    yield call(deleteMultipleUsers, payload.idList);
    yield put({ type: MULTIPLE_USERS_DELETE_SUCCESS, payload });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Users successfully deleted", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchDeleteMultipleUsers() {
  yield takeLatest(MULTIPLE_USERS_DELETE, doDeleteMultipleUsers);
}
