import {
  LOGIN_REQUESTING,
  SUBMIT_FORGOT_PASSWORD_REQUESTING,
  VALIDATE_RESET_TOKEN_REQUESTING,
  RESET_PASSWORD_REQUESTING,
} from "../constants/auth";

const loginRequest = ({ email, password }) => {
  return {
    type: LOGIN_REQUESTING,
    payload: { email, password },
  };
};

export const forgotPasswordRequest = ({ email }, history) => {
  return {
    type: SUBMIT_FORGOT_PASSWORD_REQUESTING,
    payload: { email },
    history,
  };
};
export const validatePasswordResetToken = ({ token }) => {
  return {
    type: VALIDATE_RESET_TOKEN_REQUESTING,
    payload: { token },
  };
};

export const resetPassword = ({ token, password, confirmPassword }, history) => {
  return {
    type: RESET_PASSWORD_REQUESTING,
    payload: { token, password, confirmPassword },
    history,
  };
};

export default loginRequest;
