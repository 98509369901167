export const ALL_LOCATIONS_REQUEST = "ALL_LOCATIONS_REQUEST";
export const ALL_LOCATIONS_REQUEST_SUCCESS = "ALL_LOCATIONS_REQUEST_SUCCESS";
export const ALL_LOCATIONS_REQUEST_FAILURE = "ALL_LOCATIONS_REQUEST_FAILURE";

export const ADD_LOCATION = "ADD_LOCATION";
export const ADD_LOCATION_SUCCESS = "ADD_LOCATION_SUCCESS";

export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const UPDATE_LOCATION_SUCCESS = "UPDATE_LOCATION_SUCCESS";

export const DELETE_LOCATION = "DELETE_LOCATION";
export const DELETE_LOCATION_SUCCESS = "DELETE_LOCATION_SUCCESS";

export const MULTIPLE_LOCATIONS_ACTIVE = "MULTIPLE_LOCATIONS_ACTIVE";
export const MULTIPLE_LOCATIONS_ACTIVE_SUCCESS =
  "MULTIPLE_LOCATIONS_ACTIVE_SUCCESS";
export const MULTIPLE_LOCATIONS_INACTIVE = "MULTIPLE_LOCATIONS_INACTIVE";
export const MULTIPLE_LOCATIONS_INACTIVE_SUCCESS =
  "MULTIPLE_LOCATIONS_INACTIVE_SUCCESS";
export const MULTIPLE_LOCATIONS_DELETE = "MULTIPLE_LOCATIONS_DELETE";
export const MULTIPLE_LOCATIONS_DELETE_SUCCESS =
  "MULTIPLE_LOCATIONS_DELETE_SUCCESS";
