export const REQUEST_MY_LOCATIONS_AND_ROOMS = "REQUEST_MY_LOCATIONS_AND_ROOMS";
export const REQUEST_MY_LOCATIONS_AND_ROOMS_SUCCESS = "REQUEST_MY_LOCATIONS_AND_ROOMS_SUCCESS";
export const REQUEST_MY_LOCATIONS_AND_ROOMS_FAIL = "REQUEST_MY_LOCATIONS_AND_ROOMS_FAIL";

export const REQUEST_MY_BOOKING_LIMITS = "REQUEST_MY_BOOKING_LIMITS";
export const REQUEST_MY_BOOKING_LIMITS_SUCCESS = "REQUEST_MY_BOOKING_LIMITS_SUCCESS";

export const CHANGE_CURRENT_LOCATION = "CHANGE_CURRENT_LOCATION";
export const CHANGE_CURRENT_ROOM = "CHANGE_CURRENT_ROOM";

export const REQUEST_MY_BOOKINGS = "REQUEST_MY_BOOKINGS";
export const REQUEST_MY_BOOKINGS_SUCCESS = "REQUEST_MY_BOOKINGS_SUCCESS";
export const REQUEST_MY_BOOKINGS_FAIL = "REQUEST_MY_BOOKINGS_FAIL";

export const ADD_BOOKING_REQUEST = "ADD_BOOKING_REQUEST";
export const ADD_BOOKING_REQUEST_SUCCESS = "ADD_BOOKING_REQUEST_SUCCESS";
export const ADD_BOOKING_REQUEST_FAIL = "ADD_BOOKING_REQUEST_FAIL";

export const UPDATE_BOOKING = "UPDATE_BOOKING";
export const UPDATE_BOOKING_SUCCESS = "UPDATE_BOOKING_SUCCESS";

export const DELETE_BOOKING = "DELETE_BOOKING";
export const DELETE_BOOKING_SUCCESS = "DELETE_BOOKING_SUCCESS";

export const SET_VIEW = "SET_VIEW";
export const SET_DATE = "SET_DATE";

export const REQUEST_ROOMS_AVAILABILITY = "REQUEST_ROOMS_AVAILABILITY";
export const REQUEST_ROOMS_AVAILABILITY_SUCCESS = "REQUEST_ROOMS_AVAILABILITY_SUCCESS";
