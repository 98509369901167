import {
  ACCEPT_COMPANY_APPLICATION_REQUEST,
  ADD_COMPANY,
  CREATE_COMPANY_APPLICATION_REQUEST,
  DELETE_COMPANY,
  MULTIPLE_COMPANIES_DELETE,
  REJECT_COMPANY_APPLICATION_REQUEST,
  UPDATE_COMPANY,
} from "../constants/company";

export const addCompany = (company) => {
  return {
    type: ADD_COMPANY,
    payload: { company },
  };
};

export const updateCompany = (id, company) => {
  return {
    type: UPDATE_COMPANY,
    payload: { id, company },
  };
};

export const deleteCompany = (id) => {
  return {
    type: DELETE_COMPANY,
    payload: { id },
  };
};

export const deleteMultiple = (idList) => {
  return {
    type: MULTIPLE_COMPANIES_DELETE,
    payload: {idList},
  };
};


export const createCompanyApplication = (companyApplication) => {
  return {
    type: CREATE_COMPANY_APPLICATION_REQUEST,
    payload: {companyApplication},
  };
};

export const acceptCompanyApplication = (id) => {
  return {
    type: ACCEPT_COMPANY_APPLICATION_REQUEST,
    payload: {id},
  };
};
export const rejectCompanyApplication = (id) => {
  return {
    type: REJECT_COMPANY_APPLICATION_REQUEST,
    payload: {id},
  };
};
