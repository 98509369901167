import {
  CHANGE_CURRENT_LOCATION,
  CHANGE_CURRENT_ROOM,
  REQUEST_MY_BOOKINGS,
  ADD_BOOKING_REQUEST,
  UPDATE_BOOKING,
  DELETE_BOOKING,
  SET_VIEW,
  SET_DATE,
  REQUEST_ROOMS_AVAILABILITY,
  REQUEST_MY_BOOKING_LIMITS,
} from "../constants/calendar";

export const setView = (view) => {
  return {
    type: SET_VIEW,
    payload: { view },
  };
};

export const setDate = (date) => {
  return {
    type: SET_DATE,
    payload: { date },
  };
};

export const changeLocation = (id) => {
  return {
    type: CHANGE_CURRENT_LOCATION,
    payload: { id },
  };
};
export const changeRoom = (id = 0) => {
  return {
    type: CHANGE_CURRENT_ROOM,
    payload: { id },
  };
};

export const requestAvailableRoomsForTimeAndLocation = (booking) => {
  return { type: REQUEST_ROOMS_AVAILABILITY, payload: { booking } };
};

export const requestBookings = (roomId) => {
  return {
    type: REQUEST_MY_BOOKINGS,
    payload: { roomId },
  };
};

export const requestAllBookingsForLocation = (locationId) => {
  return {
    type: REQUEST_MY_BOOKINGS,
    payload: { locationId },
  };
};

export const requestAllLimitsForLocation = (locationId) => {
  return {
    type: REQUEST_MY_BOOKING_LIMITS,
    payload: { locationId },
  };
};

export const addBooking = (booking) => {
  return {
    type: ADD_BOOKING_REQUEST,
    payload: { booking },
  };
};

export const updateBooking = (id, booking) => {
  return {
    type: UPDATE_BOOKING,
    payload: { id, booking },
  };
};

export const deleteBooking = (id) => {
  return {
    type: DELETE_BOOKING,
    payload: { id },
  };
};
