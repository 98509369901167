import {
  ALL_LOCATIONS_REQUEST_SUCCESS,
  ADD_LOCATION_SUCCESS,
  UPDATE_LOCATION_SUCCESS,
  DELETE_LOCATION_SUCCESS,
  MULTIPLE_LOCATIONS_ACTIVE_SUCCESS,
  MULTIPLE_LOCATIONS_INACTIVE_SUCCESS,
  MULTIPLE_LOCATIONS_DELETE_SUCCESS,
} from "../constants/locations";

const initialState = {
  locations: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_LOCATIONS_REQUEST_SUCCESS:
      return {
        locations: action.payload,
      };
    case ADD_LOCATION_SUCCESS:
      return {
        ...state,
        locations: [...state.locations, action.payload.location],
      };
    case UPDATE_LOCATION_SUCCESS:
      return {
        locations: state.locations.map((item) => {
          return action.payload.location.id === item.id
            ? { ...action.payload.location }
            : { ...item };
        }),
      };
    case DELETE_LOCATION_SUCCESS:
      return {
        locations: state.locations.filter(
          (item) => action.payload.id !== item.id
        ),
      };
    case MULTIPLE_LOCATIONS_ACTIVE_SUCCESS:
      return {
        locations: state.locations.map((item) => {
          return action.payload.idList.includes(item.id)
            ? { ...item, isActive: true }
            : { ...item };
        }),
      };
    case MULTIPLE_LOCATIONS_INACTIVE_SUCCESS:
      return {
        locations: state.locations.map((item) => {
          return action.payload.idList.includes(item.id)
            ? { ...item, isActive: false }
            : { ...item };
        }),
      };
    case MULTIPLE_LOCATIONS_DELETE_SUCCESS:
      return {
        locations: state.locations.filter(
          (item) => !action.payload.idList.includes(item.id)
        ),
      };
    default:
      return state;
  }
};
export default reducer;
