import React from "react";

import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: theme.breakpoints.values.sm,
  },
}));

const RulesDialog = ({ open, handleClose, rules }) => {
  const classes = useStyles();
  const rulesList = rules.split("\n");

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="rules-dialog-title"
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title" disableTypography>
        <Typography variant="h3">{"Room Rules"}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Container disableGutters className={classes.root}>
            <ol>
              {rulesList.map((rule, index) => (
                <Typography
                  key={`rule-nr-${index}`}
                  gutterBottom
                  variant="h4"
                  component="p"
                >
                  <li>{rule}</li>
                </Typography>
              ))}
            </ol>
          </Container>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RulesDialog;
