import React from "react";

import Company from "../components/Company";
import CompanyDetails from "../components/CompanyDetails";

import Grid from "@material-ui/core/Grid";

const CompanyView = ({ company, validationSchema, companyUpdateHandler, requesting, isOwner }) => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item lg={4} md={6} xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Company
                company={company}
                companyUpdateHandler={companyUpdateHandler}
                isOwner={isOwner}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={8} md={6} xs={12}>
          <CompanyDetails
            company={company}
            validationSchema={validationSchema}
            companyUpdateHandler={companyUpdateHandler}
            requesting={requesting}
            isOwner={isOwner}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CompanyView;
