import React from "react";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import DeleteIcon from "@material-ui/icons/Delete";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  iconButton: {},
  iconContainer: {
    marginRight: "24px",
  },
  inverseIcon: {
    transform: "rotate(90deg)",
  },
}));

const CustomToolbar = ({
  selectedRows,
  displayData,
  setSelectedRows,
  handleMarkSelectedAsActive,
  handleMarkSelectedAsInactive,
  handleDeleteSelected,
}) => {
  const classes = useStyles();
  const handleClickDeselectAll = () => {
    setSelectedRows([]);
  };
  const handleClickInverseSelection = () => {
    const nextSelectedRows = displayData.reduce((nextSelectedRows, _, index) => {
      if (!selectedRows.data.find((selectedRow) => selectedRow.index === index)) {
        nextSelectedRows.push(index);
      }

      return nextSelectedRows;
    }, []);

    setSelectedRows(nextSelectedRows);
  };

  const myRows = selectedRows.data.map((item) => displayData[item.index]).map((row) => row.data[0]);

  return (
    <div className={classes.iconContainer}>
      <Tooltip title={"Deselect ALL"}>
        <IconButton className={classes.iconButton} onClick={handleClickDeselectAll}>
          <IndeterminateCheckBoxIcon className={classes.icon} />
        </IconButton>
      </Tooltip>
      <Tooltip title={"Inverse selection"}>
        <IconButton className={classes.iconButton} onClick={handleClickInverseSelection}>
          <CompareArrowsIcon className={[classes.icon, classes.inverseIcon].join(" ")} />
        </IconButton>
      </Tooltip>
      {handleMarkSelectedAsActive && (
        <Tooltip title={"Mark selected as active"}>
          <IconButton
            className={classes.iconButton}
            onClick={() => {
              handleMarkSelectedAsActive(myRows);
            }}
          >
            <CheckCircleIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      )}
      {handleMarkSelectedAsInactive && (
        <Tooltip title={"Mark selected as inactive"}>
          <IconButton
            className={classes.iconButton}
            onClick={() => {
              handleMarkSelectedAsInactive(myRows);
            }}
          >
            <CancelIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      )}
      {handleDeleteSelected && (
        <Tooltip title={"Delete selected"}>
          <IconButton
            className={classes.iconButton}
            onClick={() => {
              handleDeleteSelected(myRows);
            }}
          >
            <DeleteIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export default CustomToolbar;
