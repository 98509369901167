import axios from "axios";

import config from "./baseRequestConfig";

const getAllCompanyApplicationsUrl = "/api/company-application/";
const acceptCompanyApplicationsUrl =  (id) => `/api/company-application/${id}/accept`;
const rejectCompanyApplicationsUrl =  (id) => `/api/company-application/${id}/reject`;
const createCompanyApplicationsUrl = "/api/company-application/";

export const createCompanyApplication = (company) => {
  return axios
      .post(createCompanyApplicationsUrl, company)
      .then((response) => response.data)
      .catch((error) => {
        throw error.response;
      });
};

export const getAllCompanyApplications = () => {
  const token = config();
  const cfg = {
    headers: { ...axios.defaults.headers, Authorization: token },
  };
  return axios
    .get(getAllCompanyApplicationsUrl, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};
export const acceptCompany = (id) => {
  const token = config();
  const cfg = {
    headers: {
      ...axios.defaults.headers,
      Authorization: token,
    },
  };
  return axios
    .get(acceptCompanyApplicationsUrl(id), cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

export const rejectCompany = (id) => {
  const token = config();
  const cfg = {
    headers: {
      ...axios.defaults.headers,
      Authorization: token,
    },
  };
  return axios
      .get(rejectCompanyApplicationsUrl(id), cfg)
      .then((response) => response.data)
      .catch((error) => {
        throw error.response;
      });
};
