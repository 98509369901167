import { takeLatest, call, put } from "redux-saga/effects";
import { sendNotification } from "../../utils/sendNotification";

import {
  RESET_PIN,
  USER_REQUEST,
  USER_REQUEST_SUCCESS,
  USER_UPDATE_PROFILE,
} from "../constants/user";

import {
  REQUEST_STARTED,
  REQUEST_FAIL,
  REQUEST_SUCCESS,
  REQUEST_FINISHED,
} from "../constants/request";

import { getMyProfile, updateProfile, resetPin } from "../../services/user.service";

export function* doRequestProfile(action) {
  try {
    yield put({ type: REQUEST_STARTED });

    const payload = yield call(getMyProfile);
    yield put({ type: USER_REQUEST_SUCCESS, payload: payload });
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchRequestProfile() {
  yield takeLatest(USER_REQUEST, doRequestProfile);
}

export function* doUpdateProfile(action) {
  try {
    const { id, payload } = action;
    yield put({ type: REQUEST_STARTED });

    const user = yield call(updateProfile, id, payload);
    yield put({ type: USER_REQUEST_SUCCESS, payload: { ...user } });

    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Profile updated", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");

    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchUpdateProfile() {
  yield takeLatest(USER_UPDATE_PROFILE, doUpdateProfile);
}

export function* doPinReset(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    yield call(resetPin, payload.id);
    yield put({ type: REQUEST_SUCCESS });
    yield put({ type: USER_REQUEST });
    sendNotification("Pin successfully updated", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchPinReset() {
  yield takeLatest(RESET_PIN, doPinReset);
}
