import React from "react";
import PropTypes from "prop-types";

import { Formik, Field } from "formik";
import { TextField } from "formik-material-ui";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";

const CompanyDetails = ({
  company,
  validationSchema,
  companyUpdateHandler,
  requesting,
  isOwner,
}) => {
  return (
    <Formik
      initialValues={{
        name: company.name,
        office: company.office,
      }}
      validateOnChange={true}
      validationSchema={validationSchema}
      onSubmit={companyUpdateHandler}
      enableReinitialize={true}
    >
      {({ submitForm, isSubmitting, status, initialValues }) => (
        <>
          <Card>
            <CardHeader
              subheader={isOwner ? "The information can be edited" : ""}
              title="Company Details"
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Field
                    component={TextField}
                    variant="outlined"
                    required
                    fullWidth
                    id="name"
                    label="Company Name"
                    disabled={requesting || !isOwner}
                    name="name"
                    autoComplete="company-name"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    component={TextField}
                    variant="outlined"
                    required
                    fullWidth
                    id="office"
                    label="Company Room Number"
                    disabled={requesting || !isOwner}
                    InputProps={{
                      placeholder: "Room 201",
                    }}
                    name="office"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                color="primary"
                variant="contained"
                onClick={submitForm}
                disabled={requesting || !isOwner}
              >
                Save details
              </Button>
            </Box>
          </Card>
        </>
      )}
    </Formik>
  );
};

CompanyDetails.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    image: PropTypes.string,
    created: PropTypes.string,
    updated: PropTypes.string,
    OwnerId: PropTypes.number,
    pinCode: PropTypes.string,
  }),
};
export default CompanyDetails;
