import {
  ADD_CANTEEN,
  UPDATE_CANTEEN,
  DELETE_CANTEEN,
  ADD_CANTEEN_ITEM,
  UPDATE_CANTEEN_ITEM,
  DELETE_CANTEEN_ITEM,
  QUERY_CANTEEN_REPORT_REQUEST,
  QUERY_CANTEEN_REPORT_CLEANUP,
  QUERY_COMPANY_CANTEEN_REPORT_REQUEST,
  QUERY_COMPANY_CANTEEN_REPORT_CLEANUP,
} from "../constants/canteen";

export const addCanteen = (canteen) => {
  return {
    type: ADD_CANTEEN,
    payload: { canteen },
  };
};
export const updateCanteen = (id, canteen) => {
  return {
    type: UPDATE_CANTEEN,
    payload: { id, canteen },
  };
};

export const deleteCanteen = (id) => {
  return {
    type: DELETE_CANTEEN,
    payload: { id },
  };
};

export const addCanteenItem = (item) => {
  return {
    type: ADD_CANTEEN_ITEM,
    payload: { item },
  };
};
export const updateCanteenItem = (id, item) => {
  return {
    type: UPDATE_CANTEEN_ITEM,
    payload: { id, item },
  };
};

export const deleteCanteenItem = (id) => {
  return {
    type: DELETE_CANTEEN_ITEM,
    payload: { id },
  };
};

export const queryCanteenReport = (query) => {
  return {
    type: QUERY_CANTEEN_REPORT_REQUEST,
    payload: { query },
  };
};
export const cleanupReport = (query) => {
  return {
    type: QUERY_CANTEEN_REPORT_CLEANUP,
  };
};

export const queryCompanyCanteenReport = (query) => {
  return {
    type: QUERY_COMPANY_CANTEEN_REPORT_REQUEST,
    payload: { query },
  };
};
export const cleanupCompanyReport = (query) => {
  return {
    type: QUERY_COMPANY_CANTEEN_REPORT_CLEANUP,
  };
};
