import React from "react";

import { useSelector, useDispatch } from "react-redux";

import { changeLocation, changeRoom } from "../redux/actions/calendar";

import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  toolbarItem: {
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  chipItem: { marginLeft: theme.spacing(1) },

  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  paper: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    maxHeight: "200px",
    overflow: "auto",
    zIndex: "1200",
    "& *": {
      zIndex: "1200",
    },
  },
}));

const CalendarTopbar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const locations = useSelector((state) => state.calendar.locations);
  const currentLocation = useSelector((state) => state.calendar.currentLocation);
  const currentLimits = useSelector((state) => state.calendar.currentLimits);
  const rooms = useSelector((state) => state.calendar.rooms);
  const currentRoom = useSelector((state) => state.calendar.currentRoom);

  const requesting = useSelector((state) => state.request.requesting);

  const [locationsAnchor, setLocationsAnchor] = React.useState(null);
  const [roomsAnchor, setRoomsAnchor] = React.useState(null);
  const [limitsAnchor, setLimitsAnchor] = React.useState(null);

  const limits = React.useMemo(() => {
    return currentLimits.map((limit) => {
      const correspondingRoom = currentLocation.rooms.filter((room) => room.id === limit.room)[0];
      if (correspondingRoom) {
        return {
          room: correspondingRoom.name,
          usedToday: limit.dayUsed,
          maxToday: correspondingRoom.dailyLimit,
          usedThisWeek: limit.weekUsed,
          maxThisWeek: correspondingRoom.weeklyLimit,
        };
      } else {
        return {
          room: "undefined",
          usedToday: "undefined",
          maxToday: "undefined",
          usedThisWeek: "undefined",
          maxThisWeek: "undefined",
        };
      }
    });
  }, [currentLimits, currentLocation]);

  const handleLimitsClick = (event) => {
    setLimitsAnchor(event.currentTarget);
  };
  const handleLimitsClose = () => {
    setLimitsAnchor(null);
  };

  const handleClickLocationsList = (event) => {
    setLocationsAnchor(event.currentTarget);
  };
  const handleClickRoomsList = (event) => {
    setRoomsAnchor(event.currentTarget);
  };

  const handleLocationsMenuItemClick = (id) => {
    dispatch(changeLocation(id));
    setLocationsAnchor(null);
  };
  const handleRoomsMenuItemClick = (id) => {
    dispatch(changeRoom(id));
    setRoomsAnchor(null);
  };
  const handleAllRoomsMenuItemClick = () => {
    dispatch(changeRoom());
    setRoomsAnchor(null);
  };

  const handleLocationsMenuClose = () => {
    setLocationsAnchor(null);
  };
  const handleRoomsMenuClose = () => {
    setRoomsAnchor(null);
  };

  if (requesting) {
    return <></>;
  }
  return (
    <>
      <Toolbar className={classes.sectionMobile}>
        <div>
          {currentLocation ? (
            <div className={classes.toolbarItem}>
              <List component="nav">
                <ListItem button onClick={handleClickLocationsList}>
                  <ListItemText primary="Location" secondary={currentLocation.name} />
                </ListItem>
              </List>
              <Menu
                id="locations-menu"
                anchorEl={locationsAnchor}
                keepMounted
                open={Boolean(locationsAnchor)}
                onClose={handleLocationsMenuClose}
              >
                {locations.map((location, index) => (
                  <MenuItem
                    key={`location-${location.id}-option`}
                    selected={location.id === currentLocation.id}
                    onClick={(event) => handleLocationsMenuItemClick(location.id)}
                  >
                    {location.name}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          ) : (
            "No locations found"
          )}
        </div>
        <div>
          {currentRoom ? (
            <div className={classes.toolbarItem}>
              <List component="nav">
                <ListItem button onClick={handleClickRoomsList}>
                  <ListItemText primary="Room" secondary={currentRoom.name} />
                </ListItem>
              </List>
              <Menu
                id="rooms-menu"
                anchorEl={roomsAnchor}
                keepMounted
                open={Boolean(roomsAnchor)}
                onClose={handleRoomsMenuClose}
              >
                <MenuItem key={`room-all-option`} onClick={() => handleAllRoomsMenuItemClick()}>
                  {"ALL"}
                </MenuItem>
                {rooms.map((room, index) => (
                  <MenuItem
                    key={`room-${room.id}-option`}
                    selected={room.id === currentRoom.id}
                    onClick={(event) => handleRoomsMenuItemClick(room.id)}
                  >
                    {room.name}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          ) : (
            <div className={classes.toolbarItem}>
              <List component="nav">
                <ListItem button onClick={handleClickRoomsList}>
                  <ListItemText primary="Room" secondary={"ALL"} />
                </ListItem>
              </List>
              <Menu
                id="rooms-menu"
                anchorEl={roomsAnchor}
                keepMounted
                open={Boolean(roomsAnchor)}
                onClose={handleRoomsMenuClose}
              >
                <MenuItem
                  key={`room-all-option`}
                  selected={true}
                  onClick={() => handleAllRoomsMenuItemClick()}
                >
                  {"ALL"}
                </MenuItem>
                {rooms.map((room, index) => (
                  <MenuItem
                    key={`room-${room.id}-option`}
                    onClick={(event) => handleRoomsMenuItemClick(room.id)}
                  >
                    {room.name}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          )}
        </div>
        <div>
          {currentRoom && currentRoom.features ? (
            <div className={classes.toolbarItem}>
              <div>
                {currentRoom.features
                  .filter((feature) => feature.isActive)
                  .map((feature, index) => (
                    <span key={`chip-feature-${feature.id}`}>
                      {feature.name}
                      {index + 1 === currentRoom.features.length ? "" : ", "}
                    </span>
                  ))}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </Toolbar>
      <Toolbar className={classes.sectionDesktop}>
        {currentLocation ? (
          <>
            <Typography variant="button">Location:</Typography>
            <div className={classes.toolbarItem}>
              <List component="nav">
                <ListItem button onClick={handleClickLocationsList}>
                  <ListItemText secondary={currentLocation.name} />
                </ListItem>
              </List>
              <Menu
                id="locations-menu"
                anchorEl={locationsAnchor}
                keepMounted
                open={Boolean(locationsAnchor)}
                onClose={handleLocationsMenuClose}
              >
                {locations.map((location, index) => (
                  <MenuItem
                    key={`location-${location.id}-option`}
                    selected={location.id === currentLocation.id}
                    onClick={(event) => handleLocationsMenuItemClick(location.id)}
                  >
                    {location.name}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </>
        ) : (
          "No locations found"
        )}
        {currentRoom ? (
          <>
            <Typography variant="button">Room:</Typography>
            <div className={classes.toolbarItem}>
              <List component="nav">
                <ListItem button onClick={handleClickRoomsList}>
                  <ListItemText secondary={currentRoom.name} />
                </ListItem>
              </List>
              <Menu
                id="rooms-menu"
                anchorEl={roomsAnchor}
                keepMounted
                open={Boolean(roomsAnchor)}
                onClose={handleRoomsMenuClose}
              >
                <MenuItem key={`room-all-option`} onClick={() => handleAllRoomsMenuItemClick()}>
                  {"ALL"}
                </MenuItem>
                {rooms.map((room, index) => (
                  <MenuItem
                    key={`room-${room.id}-option`}
                    selected={room.id === currentRoom.id}
                    onClick={(event) => handleRoomsMenuItemClick(room.id)}
                  >
                    {room.name}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </>
        ) : (
          <>
            <Typography variant="button">Room:</Typography>
            <div className={classes.toolbarItem}>
              <List component="nav">
                <ListItem button onClick={handleClickRoomsList}>
                  <ListItemText secondary={"ALL"} />
                </ListItem>
              </List>
              <Menu
                id="rooms-menu"
                anchorEl={roomsAnchor}
                keepMounted
                open={Boolean(roomsAnchor)}
                onClose={handleRoomsMenuClose}
              >
                <MenuItem
                  key={`room-all-option`}
                  selected={true}
                  onClick={() => handleAllRoomsMenuItemClick()}
                >
                  {"ALL"}
                </MenuItem>
                {rooms.map((room, index) => (
                  <MenuItem
                    key={`room-${room.id}-option`}
                    onClick={(event) => handleRoomsMenuItemClick(room.id)}
                  >
                    {room.name}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </>
        )}
        {currentRoom && currentRoom.features ? (
          <>
            <Typography variant="button">Features:</Typography>
            <div className={classes.toolbarItem}>
              <div>
                {currentRoom.features
                  .filter((feature) => feature.isActive)
                  .map((feature) => (
                    <Chip
                      size="small"
                      className={classes.chipItem}
                      key={`chip-feature-${feature.id}`}
                      label={feature.name}
                      variant="outlined"
                    />
                  ))}
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        <Tooltip title="Booking time used">
          <IconButton aria-label="Booking limits" onClick={handleLimitsClick}>
            <AccessTimeIcon />
          </IconButton>
        </Tooltip>
        <Popper open={Boolean(limitsAnchor)} anchorEl={limitsAnchor} disablePortal>
          <ClickAwayListener onClickAway={handleLimitsClose}>
            <Paper className={classes.paper}>
              <>
                {limits.map((limit, index) => (
                  <div key={`limit-room-${index}`}>
                    <Typography variant="h6" component="p">
                      {limit.room}
                    </Typography>
                    <Typography variant="body1" component="p">
                      {`Today - ${limit.usedToday} / ${limit.maxToday} hours`}
                      <br />
                      {`This week - ${limit.usedThisWeek} / ${limit.maxThisWeek} hours`}
                    </Typography>
                  </div>
                ))}
              </>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </Toolbar>
    </>
  );
};

export default CalendarTopbar;
