import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  passwordColumn: {
    position: "center",
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
  },
}));

const PasswordColumn = ({ password }) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <div className={classes.passwordColumn}>
      {showPassword ? <Typography>{password}</Typography> : <Typography>••••••</Typography>}
      <IconButton onClick={handleClickShowPassword}>
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </div>
  );
};

export default PasswordColumn;
