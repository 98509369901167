export const ALL_CANTEENS_REQUEST = "ALL_CANTEENS_REQUEST";
export const ALL_CANTEENS_REQUEST_SUCCESS = "ALL_CANTEENS_REQUEST_SUCCESS";
export const ALL_CANTEENS_REQUEST_FAILURE = "ALL_CANTEENS_REQUEST_FAILURE";

export const ADD_CANTEEN = "ADD_CANTEEN";
export const ADD_CANTEEN_SUCCESS = "ADD_CANTEEN_SUCCESS";

export const UPDATE_CANTEEN = "UPDATE_CANTEEN";
export const UPDATE_CANTEEN_SUCCESS = "UPDATE_CANTEEN_SUCCESS";

export const DELETE_CANTEEN = "DELETE_CANTEEN";
export const DELETE_CANTEEN_SUCCESS = "DELETE_CANTEEN_SUCCESS";

export const ALL_CANTEEN_ITEMS_REQUEST = "ALL_CANTEEN_ITEMS_REQUEST";
export const ALL_CANTEEN_ITEMS_REQUEST_SUCCESS = "ALL_CANTEEN_ITEMS_REQUEST_SUCCESS";
export const ALL_CANTEEN_ITEMS_REQUEST_FAILURE = "ALL_CANTEEN_ITEMS_REQUEST_FAILURE";

export const ADD_CANTEEN_ITEM = "ADD_CANTEEN_ITEM";
export const ADD_CANTEEN_ITEM_SUCCESS = "ADD_CANTEEN_ITEM_SUCCESS";

export const UPDATE_CANTEEN_ITEM = "UPDATE_CANTEEN_ITEM";
export const UPDATE_CANTEEN_ITEM_SUCCESS = "UPDATE_CANTEEN_ITEM_SUCCESS";

export const DELETE_CANTEEN_ITEM = "DELETE_CANTEEN_ITEM";
export const DELETE_CANTEEN_ITEM_SUCCESS = "DELETE_CANTEEN_ITEM_SUCCESS";

export const QUERY_CANTEEN_REPORT_REQUEST = "QUERY_CANTEEN_REPORT_REQUEST";
export const QUERY_CANTEEN_REPORT_REQUEST_SUCCESS = "QUERY_CANTEEN_REPORT_REQUEST_SUCCESS";
export const QUERY_CANTEEN_REPORT_REQUEST_FAILURE = "QUERY_CANTEEN_REPORT_REQUEST_FAILURE";
export const QUERY_CANTEEN_REPORT_CLEANUP = "QUERY_CANTEEN_REPORT_CLEANUP";

export const QUERY_COMPANY_CANTEEN_REPORT_REQUEST = "QUERY_COMPANY_CANTEEN_REPORT_REQUEST";
export const QUERY_COMPANY_CANTEEN_REPORT_REQUEST_SUCCESS =
  "QUERY_COMPANY_CANTEEN_REPORT_REQUEST_SUCCESS";
export const QUERY_COMPANY_CANTEEN_REPORT_REQUEST_FAILURE =
  "QUERY_COMPANY_CANTEEN_REPORT_REQUEST_FAILURE";
export const QUERY_COMPANY_CANTEEN_REPORT_CLEANUP = "QUERY_COMPANY_CANTEEN_REPORT_CLEANUP";
