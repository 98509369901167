import { all } from "redux-saga/effects";

import * as loginSaga from "./auth";
import * as userSaga from "./user";
import * as calendarSaga from "./calendar";
import * as locationsSaga from "./locations";
import * as companiesSaga from "./companies";
import * as featuresSaga from "./features";
import * as roomsSaga from "./rooms";
import * as canteenSage from "./canteen";
import * as receptionSaga from "./reception";
import * as userAdminSaga from "./user.admin";

export default function* rootSaga() {
  yield all([
    loginSaga.loginWatcher(),
    loginSaga.watchUnauthorized(),
    loginSaga.watchForgotPasswordRequest(),
    loginSaga.watchValidateResetToken(),
    loginSaga.watchResetPassword(),
    userSaga.watchRequestProfile(),
    userSaga.watchUpdateProfile(),
    userSaga.watchPinReset(),

    calendarSaga.watchRequestMyLocationsAndRooms(),
    calendarSaga.watchRequestMyBookings(),
    calendarSaga.watchAddBooking(),
    calendarSaga.watchDeleteBooking(),
    calendarSaga.watchUpdateBooking(),
    calendarSaga.watchRequestRoomAvailability(),
    calendarSaga.watchRequestMyLimitsForLocation(),

    locationsSaga.watchRequestAllLocations(),
    locationsSaga.watchAddLocation(),
    locationsSaga.watchUpdateLocation(),
    locationsSaga.watchDeleteLocation(),
    locationsSaga.watchMultipleLocationsActive(),
    locationsSaga.watchMultipleLocationsInactive(),
    locationsSaga.watchDeleteMultipleLocations(),

    companiesSaga.watchRequestAllCompanies(),
    companiesSaga.watchAddCompany(),
    companiesSaga.watchUpdateCompany(),
    companiesSaga.watchDeleteCompany(),
    companiesSaga.watchDeleteMultipleCompanies(),
    companiesSaga.watchRequestAllCompanyApplications(),
    companiesSaga.watchCreateCompanyApplication(),
    companiesSaga.watchAcceptCompanyApplication(),
    companiesSaga.watchRejectCompanyApplication(),

    featuresSaga.watchRequestAllFeatures(),
    featuresSaga.watchAddFeature(),
    featuresSaga.watchUpdateFeature(),
    featuresSaga.watchDeleteFeature(),
    featuresSaga.watchMultipleFeaturesActive(),
    featuresSaga.watchMultipleFeaturesInactive(),
    featuresSaga.watchDeleteMultipleFeatures(),

    roomsSaga.watchRequestAllRooms(),
    roomsSaga.watchAddRoom(),
    roomsSaga.watchUpdateRoom(),
    roomsSaga.watchDeleteRoom(),
    roomsSaga.watchMultipleRoomsActive(),
    roomsSaga.watchMultipleRoomsInactive(),
    roomsSaga.watchDeleteMultipleRooms(),

    canteenSage.watchRequestAllCanteens(),
    canteenSage.watchAddCanteen(),
    canteenSage.watchUpdateCanteen(),
    canteenSage.watchDeleteCanteen(),

    canteenSage.watchRequestAllCanteenItems(),
    canteenSage.watchAddCanteenItem(),
    canteenSage.watchUpdateCanteenItem(),
    canteenSage.watchDeleteCanteenItem(),
    canteenSage.watchRequestCanteenReport(),
    canteenSage.watchRequestCompanyCanteenReport(),

    receptionSaga.watchAddReception(),
    receptionSaga.watchDeleteReception(),
    receptionSaga.watchRequestAllReceptions(),
    receptionSaga.watchUpdateReception(),

    userAdminSaga.watchRequestAllUsers(),
    userAdminSaga.watchAddUser(),
    userAdminSaga.watchUpdateUser(),
    userAdminSaga.watchDeleteUser(),
    userAdminSaga.watchDeleteMultipleUsers(),
    userAdminSaga.watchAddCompanyUser(),
    userAdminSaga.watchAddCanteenManager(),
  ]);
}
