import {
  ADD_LOCATION,
  MULTIPLE_LOCATIONS_ACTIVE,
  MULTIPLE_LOCATIONS_INACTIVE,
  MULTIPLE_LOCATIONS_DELETE,
  UPDATE_LOCATION,
  DELETE_LOCATION,
} from "../constants/locations";

export const addLocation = (location) => {
  return {
    type: ADD_LOCATION,
    payload: { location },
  };
};
export const updateLocation = (id, location) => {
  return {
    type: UPDATE_LOCATION,
    payload: { id, location },
  };
};

export const setLocationActive = (id) => {
  return {
    type: UPDATE_LOCATION,
    payload: { id, location: { isActive: true } },
  };
};

export const setLocationInactive = (id) => {
  return {
    type: UPDATE_LOCATION,
    payload: { id, location: { isActive: false } },
  };
};

export const deleteLocation = (id) => {
  return {
    type: DELETE_LOCATION,
    payload: { id },
  };
};
export const setActiveMultiple = (idList) => {
  return {
    type: MULTIPLE_LOCATIONS_ACTIVE,
    payload: { idList },
  };
};
export const setInactiveMultiple = (idList) => {
  return {
    type: MULTIPLE_LOCATIONS_INACTIVE,
    payload: { idList },
  };
};

export const deleteMultiple = (idList) => {
  return {
    type: MULTIPLE_LOCATIONS_DELETE,
    payload: { idList },
  };
};
