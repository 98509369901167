import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import loginRequest from "../redux/actions/auth";

import LoginComponent from "../components/LoginComponent";

const validationSchema = yup.object({
  email: yup.string().email("Please enter an valid email").required("Email is required"),
  password: yup.string().required("Password is required"),
});

const LoginPage = () => {
  const error = useSelector((state) => state.auth.error);
  const requesting = useSelector((state) => state.auth.requesting);

  const dispatch = useDispatch();

  const submitHandler = async (values) => {
    dispatch(loginRequest(values));
  };

  return (
    <div>
      <LoginComponent
        validationSchema={validationSchema}
        submitHandler={submitHandler}
        requesting={requesting}
        error={error}
      />
    </div>
  );
};

export default LoginPage;
