import React from "react";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

import CustomCalendarToolbar from "./CustomCalendarToolbar";
import "../calendar.css";

import theme from "../theme";

moment.locale("en-GB", {
  week: {
    dow: 1,
    doy: 1,
  },
});

const eventColor = theme.palette.primary.main;

const localizer = momentLocalizer(moment);

const CURRENT_DATE = moment().toDate();
const TODAY = moment().hours(0).minutes(0).second(0).milliseconds(0).toDate();

const ToolBarWrapper = ({ handleOpenBookingModal }) => (props) => {
  return <CustomCalendarToolbar handleOpenBookingModal={handleOpenBookingModal} {...props} />;
};
const DateCellWrapper = ({ children, value }) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      ...children.style,
      cursor: value < TODAY ? "not-allowed" : "",
    },
  });

///
function MonthEvent({ event }) {
  return (
    <span>
      <strong>{event.name}</strong>
      {` ⋅ ${moment(event.bookingStart).format("HH:mm")} – ${moment(event.bookingEnd).format(
        "HH:mm"
      )}`}
    </span>
  );
}

function EventWithRoomIncluded({ event }) {
  return (
    <span>
      <strong>{event.name}</strong>
      <br></br>
      <i>{event.room.name}</i>
    </span>
  );
}

function EventAgenda({ event }) {
  return (
    <span>
      <span>{event.name}</span>
      <br />
      <i>
        {event.user.firstName} {event.user.lastName}
      </i>{" "}
      at {event.room.name}
    </span>
  );
}

const Calendar = ({
  user,
  events,
  handleOpenBookingModal,
  handleOpenBookingDetailsModal,
  views,
  view,
  onViewChange,
  date,
  setDate,
}) => {
  // const onDateRangeChange = (range) => {
  //   setDate(getDateFromRange(moment, range));
  // };

  return (
    <BigCalendar
      date={date}
      views={views}
      onView={onViewChange}
      view={view}
      onNavigate={setDate}
      // onRangeChange={onDateRangeChange}
      // defaultDate={date}
      localizer={localizer}
      events={events}
      scrollToTime={new Date(2016, 1, 1, 8)}
      titleAccessor="name"
      formats={{
        timeGutterFormat: "HH:mm",
        eventTimeRangeFormat: (range, culture, localizer) => {
          return `${localizer.format(range.start, "HH:mm")} - ${localizer.format(
            range.end,
            "HH:mm"
          )}`;
        },
        selectRangeFormat: (range, culture, localizer) => {
          return `${localizer.format(range.start, "HH:mm")} - ${localizer.format(
            range.end,
            "HH:mm"
          )}`;
        },
        agendaTimeRangeFormat: (range, culture, localizer) => {
          return `${localizer.format(range.start, "HH:mm")} - ${localizer.format(
            range.end,
            "HH:mm"
          )}`;
        },
        agendaHeaderFormat: (range, culture, localizer) => {
          return `${localizer.format(range.start, "DD MMMM YYYY")} - ${localizer.format(
            range.end,
            "DD MMMM YYYY"
          )}`;
        },
      }}
      tooltipAccessor={(event) =>
        `${event.name} ⋅ ${moment(event.bookingStart).format("HH:mm")} – ${moment(
          event.bookingEnd
        ).format("HH:mm")}`
      }
      startAccessor="bookingStart"
      endAccessor="bookingEnd"
      selectable={true}
      step={15}
      eventPropGetter={(event) => {
        let backgroundColor;
        let color;
        let border;
        if (
          user.id === event.userId ||
          (user.role === "CompanyOwner" &&
            event.user.company &&
            user.company.id === event.user.company.id)
        ) {
          border = `3px solid white`;
          backgroundColor = eventColor;
          color = "white";
        } else {
          border = `3px solid ${eventColor}`;
          backgroundColor = "white";
          color = eventColor;
        }
        return { style: { backgroundColor, color, border } };
      }}
      components={{
        dateCellWrapper: DateCellWrapper,
        toolbar: ToolBarWrapper({ handleOpenBookingModal }),
        month: { event: MonthEvent },
        week: { event: EventWithRoomIncluded },
        day: { event: EventWithRoomIncluded },
        agenda: { event: EventAgenda },
      }}
      onSelectSlot={(slotInfo) => {
        if (
          moment(slotInfo.start).toDate() < CURRENT_DATE &&
          !moment(slotInfo.start).isSame(moment(TODAY))
        ) {
          return;
        }

        let selectStart = slotInfo.start;
        let selectEnd = slotInfo.end;
        if (moment(selectStart).date() !== moment(selectEnd).date()) {
          selectStart = selectEnd;
        }
        handleOpenBookingModal({
          name: "",
          bookingDate: moment(selectStart),
          bookingStartTime: moment(selectStart),
          bookingEndTime: moment(selectEnd),
        });
      }}
      onSelectEvent={(eventinfo) => {
        handleOpenBookingDetailsModal(eventinfo);
      }}
    />
  );
};

export default Calendar;
